<template>
    <v-btn-toggle v-model="group" @change="push" dense tile>
        <template v-for="{ text, value } in groups">
            <v-btn :key="value" v-bind="{ value }">{{ text }}</v-btn>
        </template>
    </v-btn-toggle>
</template>

<script>
import { EXHIBITION_GROUPS } from "@/assets/variables";

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    data: () => ({
        groups,
        group: groups[0].value,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query.group"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.group = this.$route.query.group || groups[0].value;
        },
        push() {
            const { ...query } = this.$route.query;

            delete query.page;
            query.group = this.group;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
