<template>
    <v-layout justify-center>
        <v-responsive max-width="1024" width="100%" height="100%">
            <list-heading title="회원상세" />

            <v-row class="ma-n2">
                <v-col cols="12" :md="isCompany ? 8 : undefined" class="pa-2">
                    <view-section-card title="기본정보" v-bind="{ loading }">
                        <v-card-text>
                            <v-row class="ma-n2">
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.username" label="아이디" readonly v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.password" label="비밀번호" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-select v-model="user.type" label="회원유형" :items="userTypes" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" sm="6" class="pa-2">
                                    <autocomplete-user-level v-model="user._level" label="회원등급" :sort="{ index: 1 }" :params="levelParams" :item-text="leveItemText" item-value="_id" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" class="pa-2"> <v-divider /> </v-col>

                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.name" label="이름" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.referrer" label="추천인코드" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.phone" label="연락처" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.email" label="이메일" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" class="pa-2"> <v-divider /> </v-col>

                                <v-col cols="12" md="4" class="pa-2">
                                    <autocomplete-bank v-model="user.bank.code" label="계좌은행" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" md="4" class="pa-2">
                                    <v-text-field v-model="user.bank.accountHolder" label="예금주" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" md="4" class="pa-2">
                                    <v-text-field v-model="user.bank.accountNumber" label="계좌번호" v-bind="attrs_input" />
                                </v-col>

                                <!-- <v-col cols="12" class="pa-2"> <v-divider /> </v-col>

                                <v-col cols="12" class="pa-2">
                                    <v-row class="ma-n2">
                                        <v-col cols="12" sm="6" class="pa-2">
                                            <v-text-field v-model="user.postcode" label="우편번호" v-bind="attrs_input" />
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.address1" label="기본주소" v-bind="attrs_input" />
                                </v-col>

                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.address2" label="상세주소" v-bind="attrs_input" />
                                </v-col> -->
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <template v-if="isCompany">
                    <v-col cols="12" md="4" class="pa-2" align-self="stretch">
                        <view-section-card title="사업자등록증" class="fill-height">
                            <v-card-text style="height: calc(100% - 54px - 1px)">
                                <v-layout justify-center class="grey lighten-2">
                                    <image-popup :src="user?.businessRegistration?.src" :attrs_avatar="{ tile: true, size: 225 }"></image-popup>
                                </v-layout>
                            </v-card-text>
                        </view-section-card>
                    </v-col>
                </template>
                <v-col cols="12" class="pa-2">
                    <view-section-card title="주의회원" hideDivider>
                        <template #actions>
                            <v-switch v-model="user.isWarning" v-bind="attrs_switch" color="error" />
                        </template>
                    </view-section-card>
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-data-table v-bind="{ items, headers, loading }" class="v-sheet--outlined">
                        <template #top>
                            <v-card-title class="subtitle-2 font-weight-bold">주문내역</v-card-title>
                            <v-divider />
                        </template>
                        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                        <template #[`item.numbers`]="{ item }">
                            <div>
                                <order-view :_order="item._order">
                                    <template #activator="{ attrs, on }">
                                        <v-btn x-small text tile class="pa-0 caption" v-bind="attrs" v-on="on">{{ item.order?.orderNo }}</v-btn>
                                    </template>
                                </order-view>
                            </div>
                            <div>{{ item.purchaseNo }}</div>
                        </template>
                        <template #[`item.order.sender`]="{ item }">
                            <div>{{ item.order.sender.name }}</div>
                            <div>{{ item.order.sender.phone }}</div>
                            <div>{{ item.order.sender.email }}</div>
                        </template>
                        <template #[`item.order.receiver`]="{ item }">
                            <div>{{ item.order.receiver.name }}</div>
                            <div>{{ item.order.receiver.phone }}</div>
                            <div>{{ item.order.receiver.email }}</div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-btn fixed right bottom fab color="primary" elevation="1" v-bind="{ loading }" @click="save">
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_input, attrs_switch, initUser, USER_TYPES } from "@/assets/variables";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import AutocompleteBank from "@/components/dumb/autocomplete-bank.vue";
import AutocompleteUserLevel from "@/components/dumb/autocomplete-user-level.vue";

const headers = [
    { width: 190, divider: true, text: "주문번호\n상품주문번호", value: "numbers", class: "white-space-pre-line" },
    { width: 100, divider: true, text: "주문일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 100, divider: true, text: "주문상태", value: "orderStatusMessage" },
    { width: 120, divider: true, text: "클레임상태", value: "claimStatusMessage" },
    { width: 120, divider: true, text: "연락처", value: "order.sender.phone" },
    { width: 200, divider: true, text: "구매상품", value: "product.name" },
    { width: 200, divider: true, text: "옵션정보", value: "name" },
    { width: 100, divider: true, text: "구매수량", value: "amount" },
    { width: 120, divider: true, text: "구매자", value: "order.sender" },
    { width: 120, text: "수취인", value: "order.receiver" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption" }));

const userTypes = Object.values(USER_TYPES);
const leveItemText = ({ codeText, index, name }) => {
    let text = "";
    if (codeText) text += `[${codeText}]`;
    if (index) text += ` ${index}`;
    text += ` ${name}`;

    return text.trim();
};

export default {
    components: {
        OrderView,
        ViewSectionCard,
        ListHeading,
        ImagePopup,
        AutocompleteBank,
        AutocompleteUserLevel,
    },
    data: () => ({
        user: initUser(),

        purchases: [],

        loading: false,

        headers,
        userTypes,
        attrs_input,
        attrs_switch,
        leveItemText,
    }),
    computed: {
        levelParams() {
            let params = {};

            if (this.user.type) params.code = this.user?.type;

            return params;
        },
        items() {
            return [...this.purchases];
        },
        isCompany() {
            return this.user?.type == USER_TYPES.COMPANY.value;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { user } = await api.console.users.get({ _id: this.$route.params._user });
                this.user = initUser(user);

                var { purchases } = await api.console.shop.purchases.getPurchases({
                    params: { _user: this.$route.params._user },
                });

                this.purchases = purchases;
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { password, ...form } = this.user;

                if (password) form.password = CryptoAES.encrypt(password);
                else delete form.password;

                await api.console.users.put(form);

                alert("수정되었습니다");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
