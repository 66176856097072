var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "유튜브 코드",
      "hideDivider": ""
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-sheet', [_c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "pt-0",
    attrs: {
      "label": "코드",
      "persistent-placeholder": ""
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.youtubeCode,
      callback: function ($$v) {
        _vm.youtubeCode = $$v;
      },
      expression: "youtubeCode"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }