<template>
    <v-row class="ma-n2">
        <v-col cols="12" md="6" class="pa-2">
            <v-select v-model="form.issue.round.type" label="발급주기" :items="roundTypes" v-bind="attrs_input" @input="emit" />
        </v-col>
        <v-expand-transition>
            <v-col cols="12" md="6" class="pa-0" v-show="showsField">
                <div class="pa-2">
                    <v-text-field :value="showsField" label="발급회차 입력방법" readonly disabled v-bind="attrs_input" />
                </div>
            </v-col>
        </v-expand-transition>
        <v-expand-transition>
            <v-col cols="12" class="pa-0" v-show="showsField == '진행회차'">
                <v-sheet class="pa-2">
                    <view-chips-field v-model="form.issue.round.includeds" label="발급진행회차" :disabled="disabled__includeds" v-bind="attrs_chipsField" @input="emit">
                        <template #chip-area>
                            <v-overlay v-if="showsField == '진행회차' && disabled__includeds" absolute color="transparent"> <span class="black--text"> 발급제외회차 항목을 모두 삭제 후 입력해주세요. </span> </v-overlay>
                        </template>
                    </view-chips-field>
                </v-sheet>
            </v-col>
        </v-expand-transition>
        <v-expand-transition>
            <v-col cols="12" class="pa-0" v-show="showsField == '제외회차'">
                <v-sheet class="pa-2">
                    <view-chips-field v-model="form.issue.round.excludeds" label="발급제외회차" :disabled="disabled__excludeds" v-bind="attrs_chipsField" @input="emit">
                        <template #chip-area>
                            <v-overlay v-if="showsField == '제외회차' && disabled__excludeds" absolute color="transparent"> <span class="black--text"> 발급진행회차 항목을 모두 삭제 후 입력해주세요. </span> </v-overlay>
                        </template>
                    </view-chips-field>
                </v-sheet>
            </v-col>
        </v-expand-transition>
    </v-row>
</template>

<script>
import { attrs_input, initShopCoupon } from "@/assets/variables";
import { ISSUE_ROUND_TYPES } from "@/assets/variables/constants";

import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";
import ViewChipsField from "@/components/console/dumb/view-chips-field.vue";

const roundTypes = Object.values(ISSUE_ROUND_TYPES);

const attrs_chipsField = {
    isCurrency: true,

    autoSort: true,
    autoIncrement: true,
};

export default {
    components: {
        ViewFieldCard,
        ViewChipsField,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),

        roundTypes,

        attrs_input,
        attrs_chipsField,
    }),
    computed: {
        showsField() {
            switch (this.form.issue.round.type) {
                case ISSUE_ROUND_TYPES.BOUNDED.value: {
                    return "진행회차";
                }
                case ISSUE_ROUND_TYPES.INFINITE.value: {
                    return "제외회차";
                }
                default: {
                    return;
                }
            }
        },
        disabled__includeds() {
            return !!this.form.issue.round.excludeds.length;
        },
        disabled__excludeds() {
            return !!this.form.issue.round.includeds.length;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>