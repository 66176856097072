<template>
    <v-card v-bind="$attrs">
        <v-card-text class="caption">
            <v-row class="ma-n4" style="word-break: keep-all">
                <template v-for="{ term, desc } in items">
                    <v-col cols="12" class="py-3 px-4" :key="term">
                        <div class="font-weight-bold grey--text">{{ term }}</div>
                        {{ desc }}
                    </v-col>
                </template>
                <v-col cols="12" class="py-3 px-4">
                    <div class="font-weight-bold grey--text">대상상품</div>
                    <template v-if="usage.target.type == USAGE_TARGET_TYPES.ALLITEMS.value"> 전체 </template>
                    <template v-if="usage.target.type == USAGE_TARGET_TYPES.PRODUCTS.value">
                        <autocomplete-product :value="usage.target._products" multiple readonly placeholder="없음" />
                    </template>
                    <template v-if="usage.target.type == USAGE_TARGET_TYPES.CATEGORY.value">
                        <autocomplete-category :value="usage.target._categories" multiple readonly placeholder="없음" />
                    </template>
                    <template v-if="usage.target.type == USAGE_TARGET_TYPES.DISPLAYS.value">
                        {{ displayCodeTexts }}
                    </template>
                    <template v-if="usage.target.type == USAGE_TARGET_TYPES.EXHIBITS.value">
                        <autocomplete-exhibition :value="usage.target._exhibitions" multiple readonly placeholder="없음" />
                    </template>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { initUsage, USAGE_TARGET_TYPES } from "@/assets/variables";

import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import AutocompleteCategory from "@/components/dumb/autocomplete-category.vue";
import AutocompleteExhibition from "@/components/dumb/autocomplete-exhibition.vue";

export default {
    components: {
        AutocompleteProduct,
        AutocompleteCategory,
        AutocompleteExhibition,
    },
    props: {
        usage: { type: Object, default: initUsage },
    },
    data: () => ({
        USAGE_TARGET_TYPES,
    }),
    computed: {
        ...mapGetters(["displayCodes"]),
        items() {
            return [
                { term: "사용대상금액", desc: this.usage.target.priceText || "-" },
                { term: "사용대상", desc: this.usage.target.typeText || "-" },
            ];
        },
        displayCodeTexts() {
            return [...(this.usage.target.displayCodes || []).map((key) => this.displayCodes.find((item) => item.value == key)?.text).filter((item) => item)].join(", ");
        },
    },
};
</script>

<style>
</style>