<template>
    <view-section-card title="검색엔진최적화(SEO)" hideDivider v-bind="{ loading }">
        <template #actions>
            <v-switch v-model="seoEnabled" :value="true" v-bind="attrs_switch" />
        </template>
        <v-expand-transition>
            <v-sheet v-show="seoEnabled">
                <v-divider />
                <v-card-text>
                    <v-text-field v-model="seo.title" label="타이틀: Title" persistent-placeholder dense hide-details class="mt-3" @input="input"></v-text-field>
                    <v-text-field v-model="author.content" label="메타태그: Author" persistent-placeholder dense hide-details class="mt-6" @input="input"></v-text-field>
                    <v-textarea v-model="decription.content" label="메타태그: Description" persistent-placeholder dense hide-details class="mt-6" @input="input"></v-textarea>
                    <v-textarea v-model="keywords.content" label="메타태그: Keywords" persistent-placeholder dense hide-details class="mt-6" @input="input"></v-textarea>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_switch } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: null },
        loading: { type: Boolean, default: false },
    },
    data: () => {
        return {
            seoEnabled: false,

            seo: {
                title: null,
                meta: [
                    { name: "author", content: null },
                    { name: "description", content: null },
                    { name: "keywords", content: null },
                ],
            },
            attrs_switch,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { seoEnabled, seo } = this.value;

                if (seoEnabled === undefined) seoEnabled = this.seoEnabled;
                if (seo === undefined) seo = this.seo;

                Object.assign(this.$data, {
                    seoEnabled,
                    seo,
                });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        input() {
            this.$set(this.value, "seoEnabled", this.seoEnabled);
            this.$set(this.value, "seo", this.seo);

            this.$emit("input", this.value);
        },
    },
    computed: {
        author() {
            var author = this.seo.meta.find((meta) => meta.name == "author");
            if (!author) {
                author = { name: "author", content: "" };
                this.seo.meta.push(author);
            }
            return author;
        },
        decription() {
            var decription = this.seo.meta.find((meta) => meta.name == "decription");
            if (!decription) {
                decription = { name: "decription", content: "" };
                this.seo.meta.push(decription);
            }
            return decription;
        },
        keywords() {
            var keywords = this.seo.meta.find((meta) => meta.name == "keywords");
            if (!keywords) {
                keywords = { name: "keywords", content: "" };
                this.seo.meta.push(keywords);
            }
            return keywords;
        },
    },
};
</script>
