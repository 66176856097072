var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "추가상품(연동)",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.relatedsConf.enabled,
            callback: function ($$v) {
              _vm.$set(_vm.form.relatedsConf, "enabled", $$v);
            },
            expression: "form.relatedsConf.enabled"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.relatedsConf.enabled,
      expression: "form.relatedsConf.enabled"
    }]
  }, [_c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('vertical-table-row', {
    attrs: {
      "term": "추가",
      "divider": "",
      "props": _vm.rowProps
    }
  }, [_c('related-list-push', _vm._g({
    attrs: {
      "product": _vm.value
    }
  }, {
    push: _vm.push
  }))], 1), _c('vertical-table-row', {
    attrs: {
      "term": "목록",
      "divider": "",
      "hideDivider": "",
      "props": _vm.rowProps
    }
  }, [_c('v-data-table', _vm._b({
    staticClass: "product-relateds-table",
    attrs: {
      "items": _vm.form.relateds,
      "item-key": "name"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            attrs: {
              "title": header.withTitle ? header.formatter.bind(item)(value, item) : undefined
            },
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.name`,
      fn: function (_ref2) {
        var value = _ref2.value,
          item = _ref2.item,
          header = _ref2.header;
        return [_c('item-custom-name-edit', _vm._g({
          attrs: {
            "value": item,
            "placeholder": _vm.$decode__productOptionName(header.formatter(value, item))
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "d-flex align-center px-4 py-2 fill-height",
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s(_vm.$decode__productOptionName(header.formatter(value, item))) + " ")])], 1)];
      }
    }, {
      key: `item.salePrice`,
      fn: function (_ref3) {
        var value = _ref3.value,
          item = _ref3.item,
          header = _ref3.header;
        return [_c('item-custom-sale-edit', _vm._g({
          attrs: {
            "value": item,
            "placeholder": header.formatter(value, item)
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "d-flex align-center px-4 py-2 fill-height",
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s(header.formatter(value, item)) + " ")])], 1)];
      }
    }, {
      key: `item.enabled`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-simple-checkbox', {
          staticClass: "d-inline-block mr-n2",
          attrs: {
            "dense": "",
            "color": "primary"
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.enabled,
            callback: function ($$v) {
              _vm.$set(item, "enabled", $$v);
            },
            expression: "item.enabled"
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "tile": "",
            "color": "red",
            "height": "48"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers
  }, false))], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }