var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    ref: "data-table",
    attrs: {
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('view-section-title', {
          attrs: {
            "title": "필터링 정보"
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function () {
              return [_c('v-btn', {
                attrs: {
                  "text": "",
                  "icon": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.push();
                  }
                }
              }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            },
            proxy: true
          }])
        }), _c('v-divider')];
      },
      proxy: true
    }, {
      key: `item.key`,
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [_c('v-select', _vm._b({
          attrs: {
            "items": _vm.keyItems,
            "item-text": function (value) {
              return `${value.name} - (${value.code})`;
            },
            "item-value": "_id"
          },
          on: {
            "input": function ($event) {
              return _vm.setKeyCode(index);
            }
          },
          model: {
            value: item._key,
            callback: function ($$v) {
              _vm.$set(item, "_key", $$v);
            },
            expression: "item._key"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input), false))];
      }
    }, {
      key: `item.value`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.code === 'Collections' ? _c('v-select', _vm._b({
          attrs: {
            "items": _vm.valueItems[item._key],
            "item-text": "name",
            "item-value": "id"
          },
          model: {
            value: item.value,
            callback: function ($$v) {
              _vm.$set(item, "value", $$v);
            },
            expression: "item.value"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input), false)) : item.code === 'Colour' ? _c('v-select', _vm._b({
          attrs: {
            "items": _vm.valueItems[item._key],
            "item-text": "name",
            "item-value": "id"
          },
          model: {
            value: item.value,
            callback: function ($$v) {
              _vm.$set(item, "value", $$v);
            },
            expression: "item.value"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input), false)) : item.code === 'Shape' ? _c('v-select', _vm._b({
          attrs: {
            "items": _vm.valueItems[item._key],
            "item-text": "name",
            "item-value": "id"
          },
          model: {
            value: item.value,
            callback: function ($$v) {
              _vm.$set(item, "value", $$v);
            },
            expression: "item.value"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input), false)) : item.code === 'Microwave safe' ? _c('v-select', _vm._b({
          attrs: {
            "items": _vm.valueItems[item._key],
            "item-text": "name",
            "item-value": "id"
          },
          model: {
            value: item.value,
            callback: function ($$v) {
              _vm.$set(item, "value", $$v);
            },
            expression: "item.value"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input), false)) : item.code === 'Dishwasher safe' ? _c('v-select', _vm._b({
          attrs: {
            "items": _vm.valueItems[item._key],
            "item-text": "name",
            "item-value": "id"
          },
          model: {
            value: item.value,
            callback: function ($$v) {
              _vm.$set(item, "value", $$v);
            },
            expression: "item.value"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input), false)) : item.code === 'Style' ? _c('v-select', _vm._b({
          attrs: {
            "items": _vm.valueItems[item._key],
            "item-text": "name",
            "item-value": "id"
          },
          model: {
            value: item.value,
            callback: function ($$v) {
              _vm.$set(item, "value", $$v);
            },
            expression: "item.value"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input), false)) : item.code === 'Material' ? _c('v-select', _vm._b({
          attrs: {
            "items": _vm.valueItems[item._key],
            "item-text": "name",
            "item-value": "id"
          },
          model: {
            value: item.value,
            callback: function ($$v) {
              _vm.$set(item, "value", $$v);
            },
            expression: "item.value"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input), false)) : item.code === 'Dimensions' ? _c('v-row', [_c('v-col', [_c('v-text-field', _vm._b({
          attrs: {
            "type": "number",
            "label": "length"
          },
          on: {
            "input": function ($event) {
              return _vm.parseNumber('length');
            }
          },
          model: {
            value: item.value.length,
            callback: function ($$v) {
              _vm.$set(item.value, "length", $$v);
            },
            expression: "item.value.length"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', [_c('v-text-field', _vm._b({
          attrs: {
            "type": "number",
            "label": "width"
          },
          on: {
            "input": function ($event) {
              return _vm.parseNumber('width');
            }
          },
          model: {
            value: item.value.width,
            callback: function ($$v) {
              _vm.$set(item.value, "width", $$v);
            },
            expression: "item.value.width"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', [_c('v-text-field', _vm._b({
          attrs: {
            "type": "number",
            "label": "height"
          },
          on: {
            "input": function ($event) {
              return _vm.parseNumber('height');
            }
          },
          model: {
            value: item.value.height,
            callback: function ($$v) {
              _vm.$set(item.value, "height", $$v);
            },
            expression: "item.value.height"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', [_c('v-text-field', _vm._b({
          attrs: {
            "type": "number",
            "label": "diameter"
          },
          on: {
            "input": function ($event) {
              return _vm.parseNumber('diameter');
            }
          },
          model: {
            value: item.value.diameter,
            callback: function ($$v) {
              _vm.$set(item.value, "diameter", $$v);
            },
            expression: "item.value.diameter"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1)], 1) : _c('v-text-field', _vm._b({
          model: {
            value: item.value,
            callback: function ($$v) {
              _vm.$set(item, "value", $$v);
            },
            expression: "item.value"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item,
          index = _ref3.index;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "icon": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.pull(index);
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-minus ")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.form.properties,
    headers: _vm.headers,
    loading: _vm.loading
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }