<template>
    <v-dialog v-model="shows" width="560" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-2">
                <v-row class="ma-n2">
                    <v-col class="pa-2" cols="12" md="8">
                        <v-text-field
                            v-model="form.name"
                            label="결제명"
                            v-bind="{
                                ...attrs_input,
                                loading,
                                disabled: !form.stock,
                            }"
                        />
                    </v-col>
                    <v-col class="pa-2" cols="12" md="4">
                        <v-currency-field
                            v-model="form.price"
                            label="결제금액"
                            suffix="원"
                            v-bind="{
                                ...attrs_input,
                                loading,
                                disabled: !form.stock,
                            }"
                        />
                    </v-col>
                    <v-col class="pa-2" cols="12" md="8">
                        <autocomplete-user
                            v-model="form._user"
                            v-bind="{
                                ...attrs_input,

                                label: '회원',
                                placeholder: '회원을 입력하세요',
                                itemText: ({ name, username }) => `${name || '-'} (${username || '-'})`,

                                sort: { name: 1, createdAt: 1, _id: 1 },

                                loading,
                                disabled: !isCreate,
                            }"
                        />
                    </v-col>
                    <v-col class="pa-2" cols="12" md="4">
                        <v-text-field
                            v-bind="{
                                ...attrs_input,

                                label: '결제상태',
                                value: !form?.paymentAt ? '미결제' : '결제완료',

                                readonly: true,
                            }"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col cols="3" class="py-0">
                    <v-switch
                        v-model="form.shows"
                        v-bind="{
                            ...attrs_switch,

                            dense: true,
                            disabled: !form.stock,
                        }"
                    >
                        <span slot="label" class="caption white-space-pre-line"> {{ form.shows ? "목록\r\n표시" : "목록\r\n미표시" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch, initProduct, PRODUCT_TYPES } from "@/assets/variables";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

const initForm = ({ code, ...form } = {}) => {
    if (!code) {
        code = Date.now().toDateTime();
        code = code.replaceAll(/[^0-9]/g, "");
        code += `_${Math.floor(Math.random() * 100000)}`;
    }

    return initProduct({
        ...form,

        code,

        type: form?.type ?? PRODUCT_TYPES.TARGET_PAYMENT.value,
        stock: form?.stock ?? 1,
        shows: form?.shows ?? true,
        sells: form?.sells ?? true,
    });
};

export default {
    components: {
        AutocompleteUser,
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initForm(),

        shows: false,
        loading: false,

        attrs_input,
        attrs_switch,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "개인결제";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initForm();
                } else {
                    let { _id } = this;
                    this.form = initForm((await api.console.shop.products.get({ _id }))?.product);
                    this.$emit("input", { ...this.form });
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { name, price, _user } = this.form;

            if (!name) throw new Error("결제명을 등록하셔야 합니다");
            if (!price) throw new Error("결제금액을 입력하셔야 합니다");
            if (!_user) throw new Error("회원을 입력하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.shop.products;
                let { ...form } = this.form;
                let product = initForm((await (this.isCreate ? post : put)(form))?.product);

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");

                this.$emit("input", product);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
        line-height: 1.3;
    }
}
</style>
