<template>
    <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" ref="data-table" mobile-breakpoint="610">
        <template #body="props">
            <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" :sort="!loading || items.length < 2" @change="setNewIndex">
                <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
            </draggable>
        </template>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

        <template #[`item.handle`]>
            <v-btn text tile color="grey darken-1" height="48" style="cursor: move" :disabled="items.length < 2">
                <v-icon>mdi-drag</v-icon>
            </v-btn>
        </template>

        <template #[`item.text`]="{ item }">
            <v-text-field v-model="item.text" class="caption" v-bind="attrs_input__verticalTable" @input="emit" />
        </template>

        <template #[`item.value`]="{ item }">
            <v-text-field v-model="item.value" :disabled="item.disabled" class="caption" v-bind="attrs_input__verticalTable" @input="emit" />
        </template>

        <template #[`item.warehouse`]="{ item }">
            <form-table-warehouse :value="item" @input="updateItem" />
        </template>

        <template #[`item.areas__available`]="{ item }">
            <form-table-areas-available :value="item" placeholder="전체" multiple class="caption" v-bind="attrs_input__verticalTable" @input="updateItem" />
        </template>

        <template #[`item.actions`]="{ item, index }">
            <v-btn text tile height="48" color="red" :disabled="item.disabled" @click="pull(index)"> <v-icon>mdi-minus</v-icon> </v-btn>
        </template>

        <template #footer>
            <v-divider />
            <v-row no-gutters align="center">
                <v-spacer />
                <v-divider vertical />
                <v-col cols="auto">
                    <v-btn text tile height="58" color="primary" @click="push"> <v-icon>mdi-plus</v-icon> </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { attrs_input__verticalTable, attrs_switch, initSettingShopShippingCode } from "@/assets/variables";

import Draggable from "vuedraggable";
import FormTableWarehouse from "./form-table-warehouse.vue";
import FormTableAreasAvailable from "./form-table-areas-available.vue";

const headers = [
    { width: +64, text: "", value: "handle", align: "center" },
    { width: 170, text: "이름", value: "text" },
    { width: 250, text: "코드", value: "value" },
    { width: 404, text: "출고지", value: "warehouse", cellClass: "max-width-0 pa-0" },
    { width: 324, text: "배송가능지역", value: "areas__available" },
    { width: +64, text: "", value: "actions" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, cellClass: item.cellClass ?? "px-0" }));

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,
        FormTableWarehouse,
        FormTableAreasAvailable,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        items: [],

        headers,
        attrs_switch,
        attrs_input__verticalTable,

        loading: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.items = [...this.value];
        },
        emit() {
            this.$emit("input", this.items);
        },
        pull(index) {
            this.items.splice(index, 1);
            this.emit();
        },
        push() {
            this.items.push(initSettingShopShippingCode());
            this.emit();
        },

        updateItem(item) {
            const index = this.items.findIndex(({ tempId }) => tempId == item.tempId);
            if (0 <= index) this.items.splice(index, 1, item);
            else this.items.push(item);
            this.emit();
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let { oldIndex, newIndex } = moved;
                this.items.splice(newIndex, 0, ...this.items.splice(oldIndex, 1));
                this.emit();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
}
</style>