var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "view-field-card",
    attrs: {
      "outlined": ""
    }
  }, 'v-card', _vm.$attrs, false), [_c('v-card-text', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('span', {
    staticClass: "d-inline-block white caption pa-1",
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "left": "8px",
      "transform": "translateY(-50%)"
    }
  }, [_vm._v(_vm._s(_vm.label))]), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }