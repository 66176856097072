import { render, staticRenderFns } from "./ShopCouponList.vue?vue&type=template&id=4ddda3a0&scoped=true"
import script from "./ShopCouponList.vue?vue&type=script&lang=js"
export * from "./ShopCouponList.vue?vue&type=script&lang=js"
import style0 from "./ShopCouponList.vue?vue&type=style&index=0&id=4ddda3a0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ddda3a0",
  null
  
)

export default component.exports