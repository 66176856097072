<template>
    <v-dialog v-model="dialog" width="1660" persistent :fullscreen="$vuetify.breakpoint.mobile">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }" class="d-flex flex-column" :tile="$vuetify.breakpoint.mobile">
            <v-card-title> 주문상세 <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="pt-3" style="flex: 1 0 auto; overflow-y: auto">
                <v-row class="mx-n2">
                    <v-col cols="12" md="4" class="d-flex flex-column pa-2">
                        <order-view-baseinfo v-bind="{ order }" />
                        <order-view-receiver v-bind="{ order }" class="mt-4" />
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <order-view-payment v-bind="{ order }" />
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <v-textarea v-model="order.memo" label="메모" color="primary" auto-grow rows="9" v-bind="attrs_input">
                            <template #append>
                                <v-btn absolute right bottom small text color="primary" class="mr-n2 mb-n2" @click="save"> 저장하기 </v-btn>
                            </template>
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" class="pa-2"> <order-view-purchases v-bind="order" /> </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch } from "@/assets/variables";

import OrderViewPayment from "./order-view-payment.vue";
import OrderViewBaseinfo from "./order-view-baseinfo.vue";
import OrderViewReceiver from "./order-view-receiver.vue";
import OrderViewPurchases from "./order-view-purchases.vue";

export default {
    components: {
        OrderViewPayment,
        OrderViewBaseinfo,
        OrderViewReceiver,
        OrderViewPurchases,
    },
    props: {
        _order: { type: String, default: null },
    },
    data: () => ({
        order: {},

        dialog: false,
        loading: false,

        attrs_input,
        attrs_switch,
    }),
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { order } = await api.console.shop.orders.getOrder({ _id: this._order });
                this.order = order;
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { _id, memo } = this.order || {};
                const { order } = await api.console.shop.orders.putOrder({ _id, memo });

                this.order = order;
                alert("저장되었습니다.");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
