var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "사용"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.usage.isActive,
            callback: function ($$v) {
              _vm.$set(_vm.form.usage, "isActive", $$v);
            },
            expression: "form.usage.isActive"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }, [_c('v-card', {
    attrs: {
      "disabled": !_vm.form.usage.isActive,
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('form-usage-dates', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', {
    staticClass: "mx-n4"
  }, [_c('v-divider')], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('form-usage-target', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', {
    staticClass: "mx-n4"
  }, [_c('v-divider')], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('form-usage-limit', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', {
    staticClass: "mx-n4"
  }, [_c('v-divider')], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-field-card', {
    attrs: {
      "label": "중복사용 가능여부"
    }
  }, [_c('v-switch', _vm._b({
    staticClass: "my-n1",
    on: {
      "change": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', {
          staticClass: "caption"
        }, [_vm.form.usage.combinable ? [_vm._v("중복사용 가능")] : [_vm._v("중복사용 불가")]], 2)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.usage.combinable,
      callback: function ($$v) {
        _vm.$set(_vm.form.usage, "combinable", $$v);
      },
      expression: "form.usage.combinable"
    }
  }, 'v-switch', _vm.attrs_switch, false))], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }