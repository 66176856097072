var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "사용기간(시작)",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.usage.startsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.usage, "startsAt", $$v);
      },
      expression: "form.usage.startsAt"
    }
  }, 'v-text-field', _vm.attrs_input__date, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "사용기간(종료)",
      "clearable": "",
      "messages": "※ 미입력 시 상시 사용"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.usage.endsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.usage, "endsAt", $$v);
      },
      expression: "form.usage.endsAt"
    }
  }, 'v-text-field', _vm.attrs_input__date, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }