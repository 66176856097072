var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    ref: "data-table",
    class: {
      'v-sheet--outlined': _vm.mode == 'form'
    },
    attrs: {
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm.mode == 'form' ? {
      key: "top",
      fn: function () {
        return [_c('view-section-title', {
          attrs: {
            "title": "옵션정보"
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function () {
              return [_c('v-btn', {
                attrs: {
                  "text": "",
                  "icon": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.push();
                  }
                }
              }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            },
            proxy: true
          }], null, false, 754014335)
        }), _c('v-divider')];
      },
      proxy: true
    } : null, _vm.mode == 'form' ? {
      key: `header.code`,
      fn: function (_ref) {
        var header = _ref.header;
        return [_c('shop-shipping-codes-form', {
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var attrs = _ref2.attrs,
                on = _ref2.on;
              return [_c('v-card', _vm._g(_vm._b({
                staticClass: "d-table-cell vertical-align-middle px-4",
                staticStyle: {
                  "color": "rgba(0, 0, 0, 0.6)"
                },
                attrs: {
                  "flat": "",
                  "tile": "",
                  "height": "48",
                  "width": "100%"
                }
              }, 'v-card', attrs, false), on), [_c('span', [_vm._v(" " + _vm._s(header.text) + " ")]), _c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-cog ")])], 1)];
            }
          }], null, true)
        })];
      }
    } : null, _vm.mode == 'form' ? {
      key: "body",
      fn: function (props) {
        return [_vm.$refs['data-table'] ? _c('draggable', {
          attrs: {
            "tag": "tbody",
            "list": props.items,
            "sort": !_vm.loading
          },
          on: {
            "change": _vm.setNewIndex
          }
        }, [_c('v-nodes', {
          attrs: {
            "vnodes": _vm.$refs['data-table'].genItems(props.items, props)
          }
        })], 1) : _vm._e()];
      }
    } : null, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref3) {
          var value = _ref3.value,
            item = _ref3.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.code`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('item-code', _vm._b({
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          }
        }, 'item-code', {
          mode: _vm.mode
        }, false))];
      }
    }, {
      key: `item.bundle.type`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('item-bundle-type', _vm._b({
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          }
        }, 'item-bundle-type', {
          mode: _vm.mode
        }, false))];
      }
    }, {
      key: `item.charge.type`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('item-charge-type', _vm._b({
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          }
        }, 'item-charge-type', {
          mode: _vm.mode
        }, false))];
      }
    }, {
      key: `item.charge`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('item-charge', _vm._b({
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          }
        }, 'item-charge', {
          mode: _vm.mode
        }, false))];
      }
    }, {
      key: `item.island`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('item-island', _vm._b({
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          }
        }, 'item-island', {
          mode: _vm.mode
        }, false))];
      }
    }, {
      key: `item.service`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('item-service', _vm._b({
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          }
        }, 'item-service', {
          mode: _vm.mode
        }, false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "icon": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.pull(item);
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-minus ")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }