var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "기본정보"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이름"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "amount-field text-align-right-field",
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-select', _vm._b({
          attrs: {
            "items": _vm.units,
            "item-text": "suffix",
            "dense": ""
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.form.unit,
            callback: function ($$v) {
              _vm.$set(_vm.form, "unit", $$v);
            },
            expression: "form.unit"
          }
        }, 'v-select', _vm.attrs_input__verticalTable, false))];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.value,
      callback: function ($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input, _vm.attrs_unit), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "text-align-right-field",
    attrs: {
      "label": "적립금액제한",
      "prefix": "최대",
      "suffix": "원",
      "messages": "※ 값이 0일 때, 적립금액제한 미적용",
      "disabled": _vm.disabled_limit
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.limit,
      callback: function ($$v) {
        _vm.$set(_vm.form, "limit", $$v);
      },
      expression: "form.limit"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input), false))], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }