<template>
    <v-layout column style="overflow: hidden; max-height: calc(100vh - 48px - 12px - 12px)">
        <list-heading title="대량등록" style="flex: 0 0 auto" />
        <v-sheet>
            <v-row>
                <v-col>
                    <v-file-input v-model="file"></v-file-input>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ loading }" @click="processInfo">상품정보 저장</v-btn>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet>
            <v-row>
                <v-col>
                    <v-file-input v-model="fileImg"></v-file-input>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ loading }" @click="processImageUpload">이미지 저장</v-btn>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet>
            <v-row>
                <v-col>
                    <v-file-input v-model="listFile"></v-file-input>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ loading }" @click="processListUpload">가격 저장</v-btn>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet>
            <v-row>
                <v-col>
                    <v-file-input v-model="contentFile"></v-file-input>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ loading }" @click="processContentUpload">상세내용 저장</v-btn>
                </v-col>
            </v-row>
        </v-sheet>
    </v-layout>
</template>

<script>
import XLSX from "xlsx";
import api from "@/api";
import { initProduct } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";

export default {
    components: {
        ListHeading,
    },
    data: () => ({
        file: null,
        fileImg: null,
        listFile: null,
        contentFile: null,
        loading: false,
    }),
    methods: {
        async processInfo() {
            if (!this.file) return;
            this.loading = true;

            const reader = new FileReader();
            reader.onload = async () => {
                let data = reader.result;
                let workBook = XLSX.read(data, { type: "binary" });
                let sheetName = workBook.SheetNames[0];
                let workSheet = workBook.Sheets[sheetName];

                let rows = XLSX.utils.sheet_to_json(workSheet);

                const batchSize = 500; // 한 번에 처리할 데이터 수
                const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

                const batchProcess = async (rows, batchSize) => {
                    for (let i = 0; i < rows.length; i += batchSize) {
                        const batch = rows.slice(i, i + batchSize);

                        await Promise.all(
                            batch.map((row, index) => {
                                let content = '<table><caption style="font-size: 24px; font-weight:bold; text-align: center; color: #333;">상품 상세 내용</caption><tbody>';

                                Object.entries(row).forEach(([key, value]) => {
                                    if (["mainproductcategory", "_color", "_collection", "_material"].includes(key)) return;
                                    content += `<tr style="border-bottom: 1px solid #f9f9f9;">
                                <td style="padding: 10px; font: bold 15px Arial, sans-serif; color: #333; text-align: left;">${key}</td>
                                <td style="padding: 10px; font-size: 14px; color: #333; text-align: left;">${value}</td>
                            </tr>`;
                                });

                                content += "</tbody></table>";

                                let diameter = {
                                    length: row["length_mm"] || 0,
                                    height: row["height_mm"] || 0,
                                    width: row["width_mm"] || 0,
                                    diameter: row["diameter_of_outlet_mm"] || 0,
                                };

                                let product = initProduct({
                                    _category: row["mainproductcategory"],
                                    code: row["articlenumber"],
                                    name: row["article_title"],
                                    content,
                                    properties: [...(row["_color"] ? [{ _key: "66e2a07e0d820d9540bedb80", code: "Colour", value: row["_color"] }] : []), ...(diameter.length ? [{ _key: "66e2a0bd0d820d9540bedbad", code: "Dimensions", value: diameter }] : []), ...(row["_collection"] ? [{ _key: "66e2ab07628c8f1d22c3ae04", code: "Collections", value: row["_collection"] }] : []), ...(row["_material"] ? [{ _key: "66e2a1160d820d9540bedbc0", code: "Material", value: row["_material"] }] : [])],
                                    stock: 1,
                                    shows: true,
                                    sells: true,
                                    sequence: i + index + 1,
                                    relatedGroupCode: row["modelnumber"],
                                });

                                return api.console.shop.products.post(product);
                            })
                        );

                        await delay(500); // 500ms 지연
                    }
                };

                try {
                    await batchProcess(rows, batchSize);
                    alert("모든 데이터가 성공적으로 저장되었습니다.");
                } catch (error) {
                    console.error("데이터 업로드 중 오류 발생:", error);
                } finally {
                    this.loading = false;
                }
            };
            reader.readAsBinaryString(this.file);
        },

        async processImageUpload() {
            if (!this.fileImg) return;
            this.loading = true;

            const reader = new FileReader();
            reader.onload = async () => {
                let data = reader.result;
                let workBook = XLSX.read(data, { type: "binary" });
                let sheetName = workBook.SheetNames[0];
                let workSheet = workBook.Sheets[sheetName];

                let rows = XLSX.utils.sheet_to_json(workSheet);

                const batchSize = 500; // 한 번에 처리할 데이터 수
                const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

                const batchProcess = async (rows, batchSize) => {
                    for (let i = 0; i < rows.length; i += batchSize) {
                        const batch = rows.slice(i, i + batchSize);

                        await Promise.all(
                            batch.map((row) => {
                                return api.console.shop.products.postImages(row);
                            })
                        );

                        await delay(500); // 배치 처리 후 500ms 지연
                    }
                };

                try {
                    await batchProcess(rows, batchSize);
                    alert("모든 이미지가 성공적으로 저장되었습니다.");
                } catch (error) {
                    console.error("이미지 업로드 중 오류 발생:", error);
                } finally {
                    this.loading = false;
                }
            };
            reader.readAsBinaryString(this.fileImg);
        },

        async processListUpload() {
            if (!this.listFile) return;
            this.loading = true;

            const reader = new FileReader();
            reader.onload = async () => {
                console.log("in");
                let data = reader.result;
                let workBook = XLSX.read(data, { type: "binary" });
                let sheetName = workBook.SheetNames[0];
                let workSheet = workBook.Sheets[sheetName];

                let rows = XLSX.utils.sheet_to_json(workSheet);

                const batchSize = 500; // 한 번에 처리할 데이터 수
                const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

                const batchProcess = async (rows, batchSize) => {
                    console.log(rows.length);
                    for (let i = 0; i < rows.length; i += batchSize) {
                        const batch = rows.slice(i, i + batchSize);

                        for (const row of batch) {
                            try {
                                let { products } = await api.console.shop.products.gets({
                                    params: { code: row?.code },
                                });

                                if (!products.length) {
                                    console.log(`code: ${row?.code} / price: ${row?.price}`);
                                }

                                if (products.length > 1) {
                                    console.log(`over: code: ${row?.code} / price: ${row?.price}`);
                                }

                                if (products.length && products.length === 1) {
                                    let form = { _id: products[0]._id, price: row?.price, sells: true, shows: true, stock: 999 };
                                    await api.console.shop.products.put(form);
                                }
                            } catch (error) {
                                console.error(`Error processing code: ${row?.code}`, error);
                            }
                        }

                        await delay(500); // 500ms 지연
                    }
                };

                try {
                    await batchProcess(rows, batchSize);
                    alert("모든 데이터가 성공적으로 저장되었습니다.");
                } catch (error) {
                    console.error("데이터 업로드 중 오류 발생:", error);
                } finally {
                    this.loading = false;
                }
            };
            reader.readAsBinaryString(this.listFile);
        },

        async processContentUpload() {
            if (!this.contentFile) return;
            this.loading = true;

            const reader = new FileReader();
            reader.onload = async () => {
                console.log("in");
                let data = reader.result;
                let workBook = XLSX.read(data, { type: "binary" });
                let sheetName = workBook.SheetNames[0];
                let workSheet = workBook.Sheets[sheetName];

                let rows = XLSX.utils.sheet_to_json(workSheet);

                const batchSize = 500; // 한 번에 처리할 데이터 수
                const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

                const batchProcess = async (rows, batchSize) => {
                    for (let i = 0; i < rows.length; i += batchSize) {
                        const batch = rows.slice(i, i + batchSize);

                        await Promise.all(
                            batch.map(async(row, index) => {
                                try {
                                    let { products } = await api.console.shop.products.gets({
                                        params: { code: row?.articlenumber },
                                });

                                console.log(products.length)

                                    if (!products.length) {
                                        console.log(`code: ${row?.articlenumber} / price: ${row?.price}`);
                                    }

                                    if (products.length > 1) {
                                        console.log(`over: code: ${row?.articlenumber} / price: ${row?.price}`);
                                    }

                                    if (products.length && products.length === 1) {
                                        let content = '<table><caption style="font-size: 24px; font-weight:bold; text-align: center; color: #333;">상품 상세 내용</caption><tbody>';

                                        Object.entries(row).forEach(([key, value]) => {
                                            if (["mainproductcategory", "_color", "_collection", "_material"].includes(key)) return;
                                            content += `<tr style="border-bottom: 1px solid #f9f9f9;">
    <td style="padding: 10px; font: bold 15px Arial, sans-serif; color: #333; text-align: left;">${key}</td>
    <td style="padding: 10px; font-size: 14px; color: #333; text-align: left;">${value}</td>
    </tr>`;
                                        });

                                        content += "</tbody></table>";
                                        let form = { _id: products[0]._id, content };

                                        await api.console.shop.products.put(form);
                                    }
                                } catch (error) {
                                    console.error(`Error processing code: ${row?.code}`, error);
                                }
                            })
                        )

                        await delay(500); // 500ms 지연
                    }
                };

                try {
                    await batchProcess(rows, batchSize);
                    alert("모든 데이터가 성공적으로 저장되었습니다.");
                } catch (error) {
                    console.error("데이터 업로드 중 오류 발생:", error);
                } finally {
                    this.loading = false;
                }
            };
            reader.readAsBinaryString(this.contentFile);
        },
    },
};
</script>
