var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "링크상품",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.link.enabled,
            callback: function ($$v) {
              _vm.$set(_vm.form.link, "enabled", $$v);
            },
            expression: "form.link.enabled"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.link.enabled,
      expression: "form.link.enabled"
    }]
  }, [_c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "링크이름",
      "placeholder": "문의하기"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.link.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.link, "name", $$v);
      },
      expression: "form.link.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "링크주소"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.link.url,
      callback: function ($$v) {
        _vm.$set(_vm.form.link, "url", $$v);
      },
      expression: "form.link.url"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }