<template>
    <v-dialog v-model="dialog" width="520" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.index" type="number" label="#" v-bind="{ ...attrs_input, loading }" :disabled="isCreate" /> </v-col>
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.label" label="라벨" v-bind="{ ...attrs_input, loading }" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-text-field v-model="form.subtitle" label="부제" v-bind="{ ...attrs_input, loading }" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-text-field v-model="form.title" label="제목" v-bind="{ ...attrs_input, loading }" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-textarea v-model="form.summary" label="요약" rows="1" auto-grow v-bind="{ ...attrs_input, loading }" /> </v-col>
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.duration[0]" label="기간(시작)" type="date" v-bind="{ ...attrs_input, loading }" clearable /> </v-col>
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.duration[1]" label="기간(종료)" type="date" v-bind="{ ...attrs_input, loading }" clearable /> </v-col>
                    <v-col cols="6" class="pa-2 pt-0">
                        <span class="caption">썸네일</span>
                        <image-input v-model="form.thumb" accept="image/*" width="122" height="122" outlined style="overflow: hidden" />
                    </v-col>
                    <v-col cols="6" class="pa-2 pt-0">
                        <span class="caption">이미지</span>
                        <image-input v-model="form.photo" accept="image/*" width="122" height="122" outlined style="overflow: hidden" />
                    </v-col>
                    <v-col cols="12" class="pa-2"> 
                        <autocomplete-product v-model="form._products" chips label="상품" multiple v-bind="{ ...attrs_input, loading, itemText }" ref="autocomplete-product">
                            <template #selection="{ parent, item, attrs, itemText }">
                                <draggable :list="form._products" :id="form._products.indexOf(item._id)" group="exhibition_group" :move="move" @change="setNewIndex">
                                    <v-chip @mousedown.stop @click.stop draggable outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                                </draggable>
                            </template>
                        </autocomplete-product> 
                    </v-col>
                    <v-col cols="12" class="pa-2"> <autocomplete-product v-model="form._products__main" label="주요상품" multiple :params="{ _id: form._products }" v-bind="{ ...attrs_input, loading, itemText }" ref="autocomplete-product" /> </v-col>
                    <v-col cols="12" class="pa-2"> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.shows" dense v-bind="attrs_switch">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "표기" : "미표기" }} </span>
                    </v-switch>
                </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import Draggable from "vuedraggable";
import { attrs_input, attrs_switch, EXHIBITION_GROUPS } from "@/assets/variables";

import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import ImageInput from "../../dumb/image-input.vue";

const initExhibition = (exhibition = {}) => ({
    ...exhibition,

    _thumb: exhibition?._thumb || null,
    _photo: exhibition?._photo || null,
    _products: exhibition?._products || [],
    _products__main: [...(exhibition?._products__main || [])],

    group: exhibition?.group || null,

    index: exhibition?.index || null,
    shows: exhibition?.shows || null,

    label: exhibition?.label || null,
    title: exhibition?.title || null,
    summary: exhibition?.summary || null,
    subtitle: exhibition?.subtitle || null,
    duration: [...(exhibition?.duration || [null, null])].map((item) => item?.toDate?.() || item),

    thumb: exhibition?.thumb || null,
    photo: exhibition?.photo || null,
});

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        AutocompleteProduct,
        ImageInput,
        Draggable
    },
    props: {
        _id: { type: String, default: null },
        group: { type: String, default: groups[0].value },
    },
    data: () => ({
        test:[],
        form: initExhibition({ group: groups[0]?.value }),
        dialog: false,
        loading: false,
        attrs_input,
        attrs_switch,

        dragged:{
            from: -1,
            to: -1,
            newIndex: -1
        }
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "기획전";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        move(value){
            this.dragged = {
                from: parseInt(value.from.id),
                to: parseInt(value.to.id),
                newIndex: value.draggedContext.futureIndex
            }
        },
        setNewIndex(value){
            if(value.removed){
                this.form._products.splice(this.dragged.to+this.dragged.newIndex, 0, this.form._products[this.dragged.from]);

                if(this.dragged.from < this.dragged.to)
                    this.form._products.splice(this.dragged.from, 1);
                else
                    this.form._products.splice(this.dragged.from+1, 1);
            }
        },
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    const { group } = this;
                    this.form = initExhibition({ group });
                } else {
                    let { _id } = this;
                    this.form = initExhibition((await api.console.shop.exhibitions.get({ _id }))?.exhibition);
                    this.$emit("input", { ...this.form });
                }
                this.$refs["autocomplete-product"]?.init?.();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { title, thumb, photo, _products = [] } = this.form;
            if (!title) throw new Error("제목을 등록하셔야 합니다");
            if (!thumb) throw new Error("썸네일을 등록하셔야 합니다");
            if (!photo) throw new Error("이미지를 등록하셔야 합니다");
            if (_products.length == 0) throw new Error("상품을 등록하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.shop.exhibitions;
                let { _thumb, _photo, thumb, photo, ...form } = this.form;
                let exhibition = (await (this.isCreate ? post : put)(form))?.exhibition;

                if (thumb instanceof File) thumb = (await api.console.shop.exhibitions.files.post({ _exhibition: exhibition._id, index: "thumb" }, thumb))?.file;
                if (_thumb && _thumb != thumb?._id) await api.console.shop.exhibitions.files.delete({ _exhibition: exhibition._id, _id: _thumb });
                _thumb = thumb?._id;

                if (photo instanceof File) photo = (await api.console.shop.exhibitions.files.post({ _exhibition: exhibition._id, index: "photo" }, photo))?.file;
                if (_photo && _photo != photo?._id) await api.console.shop.exhibitions.files.delete({ _exhibition: exhibition._id, _id: _photo });
                _photo = photo?._id;

                exhibition = (await put({ _id: exhibition._id, _thumb, _photo }))?.exhibition;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", exhibition);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        itemText(item) {
            let text = "";

            if (item?.name) text += ` ${item.name}`;
            if (!item?.shows) text += ` (미노출)`;

            return text.trim();
        },
    },
};
</script>
