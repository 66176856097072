<template>
<v-container fluid fill-height class="console-terms">
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">주문 통계</v-col>
                <v-spacer></v-spacer>
                <v-col v-show="false" cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="subtitle-1">월별매출통계</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="body-1">
                            <v-date-field v-model="monthlySalesFilter.startDate" type="month" label="시작일자" persistent-placeholder dense hide-details @input="search()"></v-date-field>
                        </v-col>
                        <v-col cols="auto" class="body-1">
                            <v-date-field v-model="monthlySalesFilter.endDate" type="month" label="종료일자" persistent-placeholder dense hide-details @input="search()"></v-date-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text class="pa-0">
                    <v-responsive width="100%" height="400">
                        <v-chart :options="monthlySalesOption"/>
                    </v-responsive>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="subtitle-1">일별매출통계</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="body-1">
                            <v-date-field v-model="dailySalesFilter.startDate" label="시작일자" persistent-placeholder dense hide-details @input="search()"></v-date-field>
                        </v-col>
                        <v-col cols="auto" class="body-1">
                            <v-date-field v-model="dailySalesFilter.endDate" label="종료일자" persistent-placeholder dense hide-details @input="search()"></v-date-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-responsive width="100%" height="400">
                                <v-chart :options="dailySalesPriceOption"/>
                            </v-responsive>
                        </v-col>
                        <v-col cols="4">
                            <v-responsive width="100%" height="400">
                                <v-chart :options="dailySalesAmountOption"/>
                            </v-responsive>
                        </v-col>
                        <v-col cols="4">
                            <v-responsive width="100%" height="400">
                                <v-chart :options="dailySalesUserOption"/>
                            </v-responsive>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="subtitle-1">요일별매출통계</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="body-1">
                            <v-date-field v-model="dayOfWeekSalesFilter.startDate" label="시작일자" persistent-placeholder dense hide-details @input="search()"></v-date-field>
                        </v-col>
                        <v-col cols="auto" class="body-1">
                            <v-date-field v-model="dayOfWeekSalesFilter.endDate" label="종료일자" persistent-placeholder dense hide-details @input="search()"></v-date-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text class="pa-0">
                    <v-responsive width="100%" height="400">
                        <v-chart :options="dayOfWeekSalesOption"/>
                    </v-responsive>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="subtitle-1">상품별매출통계</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="body-1">
                            <v-date-field v-model="productsSalesFilter.startDate" label="시작일자" persistent-placeholder dense hide-details @input="search()"></v-date-field>
                        </v-col>
                        <v-col cols="auto" class="body-1">
                            <v-date-field v-model="productsSalesFilter.endDate" label="종료일자" persistent-placeholder dense hide-details @input="search()"></v-date-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                        <v-data-table :items="productsSales" :headers="productsSalesHeaders" >
                            <template v-slot:item.product="{ item }">
                                <v-row v-if="item.product" align="center">
                                    <v-col md="auto">
                                        <v-img v-if="item.product.thumb" :src="item.product.thumb?.url" max-width="56" max-height="56"></v-img>
                                        <v-responsive v-else width="56" height="56">
                                            <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                <v-icon>mdi-image</v-icon>
                                            </v-row>
                                        </v-responsive>
                                    </v-col>
                                    <v-col>
                                        <div class="text-start">{{ item.product.name }}</div>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item.purchasePrice="{ item }">
                                <div class="pr-4">{{ item.purchasePrice.format() }}</div>
                            </template>
                            <template v-slot:item.purchaseAmount="{ item }">
                                <div class="pr-4">{{ item.purchaseAmount.format() }}</div>
                            </template>
                        </v-data-table>
                </v-card-text>
            </v-card>
        </v-responsive>
    </v-layout>
</v-container>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import ECharts from 'vue-echarts';

export default {
    components: {
        'v-chart': ECharts,
        VDateField
    },
    created() {
        this.init();
    },
    data(){
        return {
            ready: false,

            polar: {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [100000, 500000, 1000000, 5000000],
                    type: 'line',
                    areaStyle: {}
                }]
            },

            // 월별매출통계
            monthlySales: [],
            monthlySalesFilter: {
                startDate: null,
                endDate: null
            },

            // 일별매출통계
            dailySales: [],
            dailySalesFilter: {
                startDate: null,
                endDate: null,
            },

            // 요일별매출통계
            dayOfWeekSales: [],
            dayOfWeekSalesFilter: {
                startDate: null,
                endDate: null
            },

            // 상품별매출통계
            productsSales: [],
            productsSalesFilter: {
                startDate: null,
                endDate: null
            },
            productsSalesHeaders: [
                {
                    text: "상품정보",
                    value: "product"
                },
                {
                    text: "판매금액",
                    align: "right",
                    value: "purchasePrice"
                },
                {
                    text: "판매수량",
                    align: "right",
                    value: "purchaseAmount"
                },
            ]
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                this.monthlySalesFilter.startDate = (new Date().addYear(-1)).toDate().substr(0, 7);
                this.monthlySalesFilter.endDate = Date.now().toDate().substr(0, 7);

                this.dailySalesFilter.startDate = (new Date().addMonth(-1)).toDate();
                this.dailySalesFilter.endDate = Date.now().toDate();

                this.dayOfWeekSalesFilter.startDate = (new Date().addMonth(-1)).toDate();
                this.dayOfWeekSalesFilter.endDate = Date.now().toDate();

                this.productsSalesFilter.startDate = (new Date().addMonth(-1)).toDate();
                this.productsSalesFilter.endDate = Date.now().toDate();

                await this.search();

                this.ready = true;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        async search(){
            try{
                var { monthlySales } = await api.console.statistics.getMonthlySales({ params: this.monthlySalesFilter });
                var { dailySales } = await api.console.statistics.getDailySales({ params: this.dailySalesFilter });
                var { dayOfWeekSales } = await api.console.statistics.getDayOfWeekSales({ params: this.dayOfWeekSalesFilter });
                var { productsSales } = await api.console.statistics.getProductsSales({ params: this.productsSalesFilter });

                this.monthlySales = monthlySales;
                this.dailySales = dailySales;
                this.dayOfWeekSales = dayOfWeekSales;
                this.productsSales = productsSales;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    computed: {
        monthlySalesOption(){
            var xAxisData = [];
            for(var date = new Date(this.monthlySalesFilter.startDate); date <= new Date(this.monthlySalesFilter.endDate); date.addMonth(1)){
                xAxisData.push(date.getTime().toDate().substr(0, 7));
            }
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.monthlySales.map((statistic) => statistic.totalPrice),
                    type: 'line',
                    areaStyle: {}
                }]
            };
        },
        dailySalesPriceOption(){
            var xAxisData = [];
            for(var date = new Date(this.dailySalesFilter.startDate); date <= new Date(this.dailySalesFilter.endDate); date.addDate(1)){
                xAxisData.push(date.getTime().toDate());
            }
            return {
                title: { text: "일별결제금액", textStyle: { fontWeight: "normal", fontSize: 14 } },
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.dailySales.map((statistic) => statistic.totalPrice),
                    type: 'line',
                    areaStyle: {}
                }]
            };
        },
        dailySalesAmountOption(){
            var xAxisData = [];
            for(var date = new Date(this.dailySalesFilter.startDate); date <= new Date(this.dailySalesFilter.endDate); date.addDate(1)){
                xAxisData.push(date.getTime().toDate());
            }
            return {
                title: { text: "일별결제건수", textStyle: { fontWeight: "normal", fontSize: 14 } },
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    splitNumber: 1,
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.dailySales.map((statistic) => statistic.totalCount),
                    type: 'line',
                    areaStyle: {}
                }]
            };
        },
        dailySalesUserOption(){
            var xAxisData = [];
            for(var date = new Date(this.dailySalesFilter.startDate); date <= new Date(this.dailySalesFilter.endDate); date.addDate(1)){
                xAxisData.push(date.getTime().toDate());
            }
            return {
                title: { text: "일별결제자수", textStyle: { fontWeight: "normal", fontSize: 14 } },
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    splitNumber: 1
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.dailySales.map((statistic) => statistic.userCount),
                    type: 'line',
                    areaStyle: {}
                }]
            };
        },
        dayOfWeekSalesOption(){
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    data: ["월","화","수","목","금","토","일"],
                },
                yAxis: {
                    type: 'value',
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.dayOfWeekSales.map((statistic) => statistic.totalPrice),
                    type: 'bar',
                    areaStyle: {}
                }]
            };
        },
    }
}
</script>

<style>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
.echarts {
  width: 100%;
  height: 100%;
}
</style>
