<template>
    <v-data-table v-bind="{ items: form.properties, headers, loading }" disable-filtering disable-pagination disable-sort hide-default-footer :items-per-page="-1" ref="data-table">
        <template #top>
            <view-section-title title="필터링 정보">
                <template #actions>
                    <v-btn text icon color="primary" @click="push()"> <v-icon>mdi-plus</v-icon> </v-btn>
                </template>
            </view-section-title>
            <v-divider />
        </template>
        <template #[`item.key`]="{ item, index }">
            <v-select v-model="item._key" :items="keyItems" :item-text="(value) => `${value.name} - (${value.code})`" item-value="_id" v-bind="{ ...attrs_input }" @input="setKeyCode(index)" />
        </template>
        <template #[`item.value`]="{ item }">
            <v-select v-if="item.code === 'Collections'" v-model="item.value" :items="valueItems[item._key]" item-text="name" item-value="id" v-bind="{ ...attrs_input }"></v-select>
            <v-select v-else-if="item.code === 'Colour'" v-model="item.value" :items="valueItems[item._key]" item-text="name" item-value="id" v-bind="{ ...attrs_input }"></v-select>
            <v-select v-else-if="item.code === 'Shape'" v-model="item.value" :items="valueItems[item._key]" item-text="name" item-value="id" v-bind="{ ...attrs_input }"></v-select>
            <v-select v-else-if="item.code === 'Microwave safe'" v-model="item.value" :items="valueItems[item._key]" item-text="name" item-value="id" v-bind="{ ...attrs_input }"></v-select>
            <v-select v-else-if="item.code === 'Dishwasher safe'" v-model="item.value" :items="valueItems[item._key]" item-text="name" item-value="id" v-bind="{ ...attrs_input }"></v-select>
            <v-select v-else-if="item.code === 'Style'" v-model="item.value" :items="valueItems[item._key]" item-text="name" item-value="id" v-bind="{ ...attrs_input }"></v-select>
            <v-select v-else-if="item.code === 'Material'" v-model="item.value" :items="valueItems[item._key]" item-text="name" item-value="id" v-bind="{ ...attrs_input }"></v-select>
            <v-row v-else-if="item.code === 'Dimensions'">
                <v-col> <v-text-field v-model="item.value.length" type="number" v-bind="{ ...attrs_input }" label="length" @input="parseNumber('length')" /> </v-col>
                <v-col> <v-text-field v-model="item.value.width" type="number" v-bind="{ ...attrs_input }" label="width" @input="parseNumber('width')" /> </v-col>
                <v-col> <v-text-field v-model="item.value.height" type="number" v-bind="{ ...attrs_input }" label="height" @input="parseNumber('height')" /> </v-col>
                <v-col> <v-text-field v-model="item.value.diameter" type="number" v-bind="{ ...attrs_input }" label="diameter" @input="parseNumber('diameter')" /> </v-col>
            </v-row>
            <v-text-field v-else v-model="item.value" v-bind="{ ...attrs_input }" />
        </template>
        <template #[`item.actions`]="{ item, index }">
            <v-btn text icon color="red" @click="pull(index)">
                <v-icon> mdi-minus </v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";
import { attrs_input, initProduct, PRODUCT_TYPES } from "@/assets/variables";
import ViewSectionTitle from "@/components/console/dumb/view-section-title.vue";

const initDimensionsValue = (value = {}) => ({
    length: value?.length || 0,
    height: value?.height || 0,
    width: value?.width || 0,
    diameter: value?.diameter || 0,
});

export const initProductProperty = (property = []) => ({
    _key: property?._key || "",
    value: property?.value || null,
    code: property?.code || null,
});
export default {
    components: {
        ViewSectionTitle,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),
        keyItems: [],
        valueItems: {},
        pushIndex: 0,

        attrs_input,
        PRODUCT_TYPES,
    }),
    computed: {
        headers() {
            return [
                { value: "key", text: "이름", width: "20%" },
                { value: "value", text: "값", width: "60%" },
                { value: "actions", text: "actions", width: "20" },
            ];
        },
    },
    mounted() {
        this.init();
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        async init() {
            let { filterings } = await api.console.shop.filterings.gets();

            this.keyItems = filterings.filter(({ type }) => type === "key");
            this.keyItems.forEach((key) => {
                this.valueItems[key._id] = filterings.filter((filtering) => filtering._key === key._id);
            });
        },
        push(item = initProductProperty()) {
            this.form.properties.push({ ...item, index: this.pushIndex });
            this.pushIndex++;
            this.emit();
        },

        pull(index) {
            this.form.properties.splice(index, 1);
            this.emit();
        },

        sync() {
            this.form = initProduct(this.value);
        },

        setKeyCode(index) {
            this.form.properties[index].code = this.keyItems.filter(({ _id }) => _id === this.form.properties[index]._key)?.[0]?.code;
            this.setValues(index);
        },

        setValues(index) {
            switch (this.form.properties[index].code) {
                case "Dimensions":
                    this.form.properties[index].value = initDimensionsValue();
                    break;
                default:
                    null;
            }
        },

        parseNumber(item) {
            let idx = this.form.properties.findIndex(({ code }) => code === "Dimensions");
            if(idx === -1) return;

            let value = this.form.properties[idx].value[item];
            this.form.properties[idx].value[item] = +value;
        },

        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
