<template>
    <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" ref="data-table" mobile-breakpoint="1080">
        <template #body="props">
            <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" :sort="!loading || items.length < 2" @change="setNewIndex">
                <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
            </draggable>
        </template>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
        <template #[`item.handle`]>
            <v-btn text tile color="grey darken-1" height="48" style="cursor: move" :disabled="items.length < 2">
                <v-icon>mdi-drag</v-icon>
            </v-btn>
        </template>
        <template #[`item.type`]="{ item }">
            <v-select v-model="item.type" :items="typeItems" v-bind="attrs_input__verticalTable" @change="(type) => onChangeType(type, item)" />
        </template>
        <template #[`item.name`]="{ item }">
            <v-text-field v-model="item.name" :disabled="item.type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value" placeholder="사이즈" v-bind="attrs_input__verticalTable" @input="emit" />
        </template>
        <template #[`item.values`]="{ item }">
            <template v-if="item.type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value">
                <options-criteria-color v-model="item.values" />
            </template>
            <template v-else>
                <v-combobox v-model="item.values" multiple item-text="name" append-icon="" placeholder="항목 작성 후 Enter키를 입력해주세요" v-bind="attrs_input__verticalTable" @input="(values) => mapCriterionValues(item, values)">
                    <template #selection="{ item, parent }">
                        <v-chip outlined label close @click:close="parent.selectItem(item)"> {{ item.name }} </v-chip>
                    </template>
                </v-combobox>
            </template>
        </template>
        <template #[`item.actions`]="{ index }">
            <v-btn text tile height="48" color="red" @click="pull(index)"> <v-icon>mdi-minus</v-icon> </v-btn>
        </template>
        <template #footer>
            <v-divider />
            <v-row no-gutters align="center">
                <v-col>
                    <v-btn text tile block height="58" color="primary" @click="validate">
                        <v-icon>mdi-chevron-double-down</v-icon>
                        <span> 목록으로 적용 </span>
                    </v-btn>
                </v-col>
                <v-divider vertical />
                <v-col cols="auto">
                    <v-btn text tile height="58" color="primary" @click="push"> <v-icon>mdi-plus</v-icon> </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { attrs_input__verticalTable, initProductOptionCriterion, initProductOptionCriterionValue, PRODUCT_OPTION_CRITERIA_TYPES } from "@/assets/variables";

import Draggable from "vuedraggable";
import OptionsCriteriaColor from "./options-criteria-color.vue";

const headers = [
    { width: +64, text: "", value: "handle", align: "center" },
    { width: 120, text: "유형", value: "type" },
    { width: 120, text: "이름", value: "name" },
    { text: "값", value: "values" },
    { width: +64, text: "", value: "actions" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, cellClass: (item.cellClass || "") + " px-0" }));

const typeItems = Object.values(PRODUCT_OPTION_CRITERIA_TYPES);

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,
        OptionsCriteriaColor,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        items: [],

        headers,
        typeItems,
        attrs_input__verticalTable,
        PRODUCT_OPTION_CRITERIA_TYPES,

        loading: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.items = [...this.value].map(initProductOptionCriterion);
        },
        emit() {
            this.$emit("input", this.items);
        },
        pull(index) {
            this.items.splice(index, 1);
            this.emit();
        },
        push() {
            this.items.push(initProductOptionCriterion());
            this.emit();
        },

        mapCriterionValues(item, values = []) {
            item.values = values.map((item) => initProductOptionCriterionValue(typeof item == "object" ? item : { name: item }));
            this.emit();
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let { oldIndex, newIndex } = moved;
                this.items.splice(newIndex, 0, ...this.items.splice(oldIndex, 1));
            } finally {
                this.loading = false;
            }
        },

        onChangeType(type, item) {
            if (type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value) {
                item.name = PRODUCT_OPTION_CRITERIA_TYPES.COLOR.text;
            }
            this.emit();
        },

        validate() {
            try {
                this.$emit("input", this.items);
                this.$emit("generate");
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>

