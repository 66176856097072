<template>
    <view-field-card v-bind="{ label, disabled }">
        <v-row class="ma-n4">
            <v-col cols="12" class="pa-0">
                <v-card flat color="transparent" style="overflow: hidden">
                    <template v-if="isCurrency">
                        <v-currency-field v-model="field" v-bind="{ ...attrs_input__verticalTable, ...$attrs }" append-icon="mdi-plus" @click:append="add" @keydown.enter="add" />
                    </template>
                    <template v-else>
                        <v-text-field v-model="field" v-bind="{ ...attrs_input__verticalTable, ...$attrs }" append-icon="mdi-plus" @click:append="add" @keydown.enter="add" />
                    </template>
                    <v-divider />
                    <v-card-text style="position: relative">
                        <v-chip-group column class="mt-n1 mb-n2" style="min-height: 48px">
                            <draggable v-model="items" :disabled="!draggable">
                                <template v-for="item in items">
                                    <v-chip :key="item" outlined label close @click:close="remove(item)"> {{ item }} </v-chip>
                                </template>
                            </draggable>
                        </v-chip-group>
                        <slot name="chip-area" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <slot />
    </view-field-card>
</template>

<script>
import { attrs_input__verticalTable } from "@/assets/variables";

import Draggable from "vuedraggable";
import ViewFieldCard from "./view-field-card.vue";

export default {
    components: {
        Draggable,
        ViewFieldCard,
    },
    props: {
        value: { type: Array, default: () => [] },
        initialFieldValue: { type: [String, Number], default: null },

        label: { type: String, default: "라벨" },
        disabled: { type: Boolean, default: false },
        draggable: { type: Boolean, default: false },
        isCurrency: { type: Boolean, default: false },

        autoSort: { type: Boolean, default: false },
        autoIncrement: { type: Boolean, default: false },
    },
    data: () => ({
        items: [],
        field: null,

        attrs_input__verticalTable,
    }),
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        init() {
            if (this.autoSort && this.autoIncrement) {
                this.sync();
                this.sort();
                this.field = (this.items[this.items.length - 1] || 0) + 1;
            }
            //
            else {
                this.sync();
                this.field = this.initialFieldValue;
            }
        },
        sync() {
            this.items = [...this.value];
        },
        emit() {
            this.$emit("input", this.items);
        },
        sort() {
            this.items.sort((a, b) => a - b);
        },
        add() {
            try {
                if (this.items.includes(this.field)) {
                    throw new Error("이미 추가된 항목입니다");
                }

                this.items.push(this.isCurrency ? +this.field : this.field);
                if (this.autoSort) this.sort();

                this.field = this.autoIncrement ? +this.field + 1 : null;

                this.emit();
            } catch (error) {
                alert(error.message);
            }
        },
        remove(targetItem) {
            this.items = this.items.filter((item) => item != targetItem);
            this.emit();
        },
    },
};
</script>


<style lang="scss" scoped>
::v-deep .v-input__slot {
    background: transparent !important;

    input {
        text-align: center;
    }
}
</style>