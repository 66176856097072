var render = function render(){
  var _vm$purchaseDashboard, _vm$purchaseDashboard2, _vm$purchaseDashboard3, _vm$purchaseDashboard4, _vm$purchaseDashboard5, _vm$purchaseDashboard6, _vm$purchaseDashboard7, _vm$purchaseDashboard8, _vm$purchaseDashboard9, _vm$purchaseDashboard10, _vm$purchaseDashboard11, _vm$purchaseDashboard12, _vm$purchaseDashboard13, _vm$purchaseDashboard14, _vm$purchaseDashboard15, _vm$purchaseDashboard16, _vm$editItem$product$, _vm$editItem$product$2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pr-2"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "teal lighten-3"
    }
  }, [_vm._v("mdi-gift")]), _vm._v("신규주문")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "height": "64"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("미결제")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "teal--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/purchases/nonpayment');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard = _vm.purchaseDashboard) === null || _vm$purchaseDashboard === void 0 ? void 0 : (_vm$purchaseDashboard2 = _vm$purchaseDashboard.orderNonpayment) === null || _vm$purchaseDashboard2 === void 0 ? void 0 : _vm$purchaseDashboard2.format()))]), _vm._v(" 건 ")], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("신규주문")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "teal--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/purchases/payment?orderStatus=ORDER_PAYMENT&claimStatus=null&claimStatus=CANCEL_REJECTED&claimStatus=EXCHANGE_REJECTED&claimStatus=RETURN_REJECTED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard3 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard3 === void 0 ? void 0 : (_vm$purchaseDashboard4 = _vm$purchaseDashboard3.orderPayment) === null || _vm$purchaseDashboard4 === void 0 ? void 0 : _vm$purchaseDashboard4.format()))]), _vm._v(" 건 ")], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("발주확인완료")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "teal--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/purchases/payment?orderStatus=ORDER_CONFIRM');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard5 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard5 === void 0 ? void 0 : (_vm$purchaseDashboard6 = _vm$purchaseDashboard5.orderConfirm) === null || _vm$purchaseDashboard6 === void 0 ? void 0 : _vm$purchaseDashboard6.format()))]), _vm._v(" 건 ")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pr-2"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "purple lighten-3"
    }
  }, [_vm._v("mdi-truck")]), _vm._v("배송관리")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "height": "64"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("배송중")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "purple--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/purchases/delivery?orderStatus=SHIPPING_CONFIRM&claimStatus=null&claimStatus=CANCEL_REJECTED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard7 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard7 === void 0 ? void 0 : (_vm$purchaseDashboard8 = _vm$purchaseDashboard7.shippingConfirm) === null || _vm$purchaseDashboard8 === void 0 ? void 0 : _vm$purchaseDashboard8.format()))]), _vm._v(" 건 ")], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("배송완료")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "purple--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/purchases/delivery?orderStatus=DELIVERY_COMPLETE&claimStatus=null&claimStatus=CANCEL_REJECTED&claimStatus=EXCHANGE_REJECTED&claimStatus=RETURN_REJECTED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard9 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard9 === void 0 ? void 0 : (_vm$purchaseDashboard10 = _vm$purchaseDashboard9.deliveryComplete) === null || _vm$purchaseDashboard10 === void 0 ? void 0 : _vm$purchaseDashboard10.format()))]), _vm._v(" 건 ")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pr-2"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "blue-grey lighten-1"
    }
  }, [_vm._v("mdi-undo-variant")]), _vm._v("취소반품관리")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "height": "64"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("취소요청")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "blue-grey--text font-weight-bold text--lighten-1 mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/purchases/cancel?claimStatus=CANCEL_REQUESTED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard11 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard11 === void 0 ? void 0 : (_vm$purchaseDashboard12 = _vm$purchaseDashboard11.cancelRequest) === null || _vm$purchaseDashboard12 === void 0 ? void 0 : _vm$purchaseDashboard12.format()))]), _vm._v(" 건 ")], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("교환요청")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "blue-grey--text font-weight-bold text--lighten-1 mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/purchases/exchange?claimStatus=EXCHANGE_REQUESTED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard13 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard13 === void 0 ? void 0 : (_vm$purchaseDashboard14 = _vm$purchaseDashboard13.exchangeRequest) === null || _vm$purchaseDashboard14 === void 0 ? void 0 : _vm$purchaseDashboard14.format()))]), _vm._v(" 건 ")], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("반품요청")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "blue-grey--text font-weight-bold text--lighten-1 mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/purchases/exchange?claimStatus=RETURN_REQUESTED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard15 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard15 === void 0 ? void 0 : (_vm$purchaseDashboard16 = _vm$purchaseDashboard15.returnRequest) === null || _vm$purchaseDashboard16 === void 0 ? void 0 : _vm$purchaseDashboard16.format()))]), _vm._v(" 건 ")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pr-2"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "light-blue lighten-3"
    }
  }, [_vm._v("mdi-bank")]), _vm._v("당일구매확정")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "height": "64"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("구매확정")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "light-blue--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    }
  }, [_vm._v("0")]), _vm._v(" 건 ")], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "py-0 px-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "indigo lighten-1"
    }
  }, [_vm._v("mdi-chart-line")]), _vm._v("월별매출통계")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "196"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.monthlySalesOption
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "blue lighten-2"
    }
  }, [_vm._v(" mdi-chart-bar ")]), _vm._v(" 일별결제금액 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "196"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.dailySalesPriceOption
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "blue lighten-2"
    }
  }, [_vm._v(" mdi-chart-bar ")]), _vm._v(" 일별결제건수 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "196"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.dailySalesAmountOption
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "blue lighten-2"
    }
  }, [_vm._v(" mdi-chart-bar ")]), _vm._v(" 일별결제자수 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "196"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.dailySalesUserOption
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "cyan"
    }
  }, [_vm._v("mdi-chart-timeline-variant")]), _vm._v(" 요일별매출통계 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "196"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.dayOfWeekSalesOption
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "green lighten-2"
    }
  }, [_vm._v(" mdi-chart-box-outline ")]), _vm._v(" 상품별매출통계 ")], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-divider'), _c('v-data-table', {
    attrs: {
      "items": _vm.productsSales,
      "headers": _vm.productsSalesHeaders,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.product`,
      fn: function (_ref) {
        var _item$product$thumb;
        var item = _ref.item;
        return [item.product ? _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [item.product.thumb ? _c('v-img', {
          attrs: {
            "src": (_item$product$thumb = item.product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url,
            "max-width": "32",
            "max-height": "32"
          }
        }) : _c('v-responsive', {
          attrs: {
            "width": "32",
            "height": "32"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v(_vm._s(item.product.name))])])], 1) : _vm._e()];
      }
    }, {
      key: `item.purchasePrice`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "pr-4"
        }, [_vm._v(_vm._s(item.purchasePrice.format()))])];
      }
    }, {
      key: `item.purchaseAmount`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "pr-4"
        }, [_vm._v(_vm._s(item.purchaseAmount.format()))])];
      }
    }], null, true)
  })], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "px-2 py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "red lighten-2"
    }
  }, [_vm._v("mdi-help-circle")]), _vm._v("상품문의")], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-divider'), _c('v-data-table', {
    attrs: {
      "headers": _vm.inquiresHeaders,
      "items": _vm.inquires,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.createdAt`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDate()) + " ")];
      }
    }, {
      key: `item.reply`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.reply ? _c('span', [_vm._v("답변완료")]) : _c('span', [_vm._v("미답변")])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editInquire(item);
            }
          }
        }, [_vm._v("mdi-pencil")])];
      }
    }], null, true)
  }), _c('v-dialog', {
    attrs: {
      "max-width": "720px"
    },
    model: {
      value: _vm.inquireDialog,
      callback: function ($$v) {
        _vm.inquireDialog = $$v;
      },
      expression: "inquireDialog"
    }
  }, [_vm.inquireDialog ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품문의")])]), _c('v-card-text', [_vm.editItem.product ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "md": "auto"
    }
  }, [_vm.editItem.product.thumb ? _c('v-img', {
    attrs: {
      "src": (_vm$editItem$product$ = _vm.editItem.product.thumb) === null || _vm$editItem$product$ === void 0 ? void 0 : _vm$editItem$product$.url,
      "max-width": "56",
      "max-height": "56"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "56",
      "height": "56"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2"
  }, [_c('div', {
    staticClass: "text-start"
  }, [_vm._v(_vm._s(_vm.editItem.product.name))])])], 1) : _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "md": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "56",
      "height": "56"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2"
  }, [_c('div', {
    staticClass: "text-start"
  }, [_vm._v("삭제된 상품")])])], 1), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의 제목",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.subject,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "subject", $$v);
      },
      expression: "editItem.subject"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의날짜",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.createdAt.toDate()
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "작성자",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.user ? `${_vm.editItem.user.name}(${_vm.editItem.user.username})` : '탈퇴회원'
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의 내용",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "content", $$v);
      },
      expression: "editItem.content"
    }
  }), _c('div', {
    staticClass: "mt-2",
    staticStyle: {
      "font-size": "0.85em"
    }
  }, [_vm._v("답변")]), _c('naver-smarteditor', {
    model: {
      value: _vm.editItem.reply,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "reply", $$v);
      },
      expression: "editItem.reply"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeInquire
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.saveInquire
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "pink lighten-3"
    }
  }, [_vm._v("mdi-comment-question")]), _vm._v("1:1 문의")], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-divider'), _c('v-data-table', {
    attrs: {
      "headers": _vm.questionsHeaders,
      "items": _vm.questions,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.product`,
      fn: function (_ref7) {
        var _item$product$thumb2;
        var item = _ref7.item;
        return [item.product ? _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [item.product.thumb ? _c('v-img', {
          attrs: {
            "src": (_item$product$thumb2 = item.product.thumb) === null || _item$product$thumb2 === void 0 ? void 0 : _item$product$thumb2.url,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v(_vm._s(item.product.name))])])], 1) : _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v("삭제된 상품")])])], 1)];
      }
    }, {
      key: `item.user`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item.user ? _c('v-row', [_c('v-col', [_vm._v(_vm._s(item.user.name) + "(" + _vm._s(item.user.username) + ")")])], 1) : _c('v-row', [_c('v-col', [_vm._v("탈퇴회원")])], 1)];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDate()) + " ")];
      }
    }, {
      key: `item.reply`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [item.reply ? _c('span', [_vm._v("답변완료")]) : _c('span', [_vm._v("미답변")])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editQuestion(item);
            }
          }
        }, [_vm._v("mdi-pencil")])];
      }
    }], null, true)
  }), _c('v-dialog', {
    attrs: {
      "max-width": "720px"
    },
    model: {
      value: _vm.questionDialog,
      callback: function ($$v) {
        _vm.questionDialog = $$v;
      },
      expression: "questionDialog"
    }
  }, [_vm.questionDialog ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품문의")])]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "문의 제목",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.subject,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "subject", $$v);
      },
      expression: "editItem.subject"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의날짜",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.createdAt.toDate()
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "작성자",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.user ? `${_vm.editItem.user.name}(${_vm.editItem.user.username})` : '탈퇴회원'
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의 내용",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "content", $$v);
      },
      expression: "editItem.content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "답변",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.reply,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "reply", $$v);
      },
      expression: "editItem.reply"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeQuestion
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.saveQuestion
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "orange lighten-2"
    }
  }, [_vm._v("mdi-message-draw")]), _vm._v("구매후기")], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-divider'), _c('v-data-table', {
    attrs: {
      "headers": _vm.reviewsHeaders,
      "items": _vm.reviews,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.createdAt`,
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDate()) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.detailsReview(item);
            }
          }
        }, [_vm._v("mdi-eye")])];
      }
    }], null, true)
  }), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.reviewDialog,
      callback: function ($$v) {
        _vm.reviewDialog = $$v;
      },
      expression: "reviewDialog"
    }
  }, [_vm.reviewDialog ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("구매후기")])]), _c('v-card-text', [_vm.editItem.product ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "md": "auto"
    }
  }, [_vm.editItem.product.thumb ? _c('v-img', {
    attrs: {
      "src": (_vm$editItem$product$2 = _vm.editItem.product.thumb) === null || _vm$editItem$product$2 === void 0 ? void 0 : _vm$editItem$product$2.url,
      "max-width": "56",
      "max-height": "56"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "56",
      "height": "56"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2"
  }, [_c('div', {
    staticClass: "text-start"
  }, [_vm._v(_vm._s(_vm.editItem.product.name))])])], 1) : _vm._e(), _c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.subject,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "subject", $$v);
      },
      expression: "editItem.subject"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "작성자",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.user ? `${_vm.editItem.user.name}(${_vm.editItem.user.username})` : '탈퇴회원'
    }
  }), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "label": "내용",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "content", $$v);
      },
      expression: "editItem.content"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeReview
    }
  }, [_vm._v("닫기")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "amber lighten-2"
    }
  }, [_vm._v("mdi-bell-ring")]), _vm._v("공지사항 "), _c('v-spacer'), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref14) {
        var attrs = _ref14.attrs,
          on = _ref14.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "bottom": "",
            "color": "accent",
            "dark": "",
            "small": ""
          },
          on: {
            "click": _vm.createNotification
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" 글쓰기 ")], 1)];
      }
    }]),
    model: {
      value: _vm.notificationDialog,
      callback: function ($$v) {
        _vm.notificationDialog = $$v;
      },
      expression: "notificationDialog"
    }
  }, [_vm.notificationDialog ? _c('v-card', [_c('v-card-title', [!_vm.editItem._id ? _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("공지사항 등록")]) : _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("공지사항 수정")])]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.subject,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "subject", $$v);
      },
      expression: "editItem.subject"
    }
  }), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "label": "내용",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "content", $$v);
      },
      expression: "editItem.content"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeNotification
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.saveNotification
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-divider'), _c('v-data-table', {
    attrs: {
      "headers": _vm.notificationsHeaders,
      "items": _vm.notifications,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.notification`,
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_c('v-row', {
          on: {
            "click": function ($event) {
              return _vm.$set(item, 'show', !item.show);
            }
          }
        }, [_c('v-col', {
          staticClass: "text-start",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(_vm._s(item.subject))]), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [!item.show ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _c('v-icon', [_vm._v("mdi-chevron-up")])], 1)], 1), _c('v-expand-transition', [_c('v-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: item.show,
            expression: "item.show"
          }]
        }, [_c('v-col', [_c('v-textarea', {
          staticClass: "text-body-1",
          attrs: {
            "readonly": "",
            "text": "",
            "hide-details": ""
          },
          model: {
            value: item.content,
            callback: function ($$v) {
              _vm.$set(item, "content", $$v);
            },
            expression: "item.content"
          }
        })], 1)], 1)], 1)];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref16) {
        var item = _ref16.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDate()) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref17) {
        var item = _ref17.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editNotification(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.removeNotification(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }