var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "400",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "light": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-user', _vm._b({
    attrs: {
      "label": "회원",
      "itemText": function (_ref2) {
        var name = _ref2.name,
          username = _ref2.username;
        return `${name} (${username})`;
      }
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form._user,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_user", $$v);
      },
      expression: "form._user"
    }
  }, 'autocomplete-user', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "코드",
      "placeholder": "미입력시 자동부여"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "적립명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "text-align-right-field",
    attrs: {
      "label": "적립금"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.amount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('form-usage', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-switch', _vm._b({
    attrs: {
      "label": "사용금지",
      "dense": ""
    },
    model: {
      value: _vm.form.isDisabled,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isDisabled", $$v);
      },
      expression: "form.isDisabled"
    }
  }, 'v-switch', _vm.attrs_switch, false))], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }