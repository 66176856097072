<template>
    <v-dialog v-model="dialog" width="560" persistent :fullscreen="$vuetify.breakpoint.mobile">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="py-5" style="flex: 0 1 auto; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2">
                        <form-basic v-model="form" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <form-issue v-model="form" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <form-usage v-model="form" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.issue.isActive" @change="emit" v-bind="attrs_switch">
                        <span slot="label" class="subtitle-2"> {{ form.issue.isActive ? "발급가능" : "발급불가" }} </span>
                    </v-switch>
                </v-col>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.usage.isActive" @change="emit" v-bind="attrs_switch">
                        <span slot="label" class="subtitle-2"> {{ form.usage.isActive ? "사용가능" : "사용불가" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_switch, initShopPoint } from "@/assets/variables";

import FormBasic from "./form-basic.vue";
import FormIssue from "./issue/form-issue.vue";
import FormUsage from "./usage/form-usage.vue";

export default {
    components: {
        FormBasic,
        FormIssue,
        FormUsage,
    },
    props: {
        value: { type: Object, default: initShopPoint },
    },
    data: () => ({
        form: initShopPoint(),

        attrs_switch,

        dialog: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this.value._id;
        },
        title() {
            let title = "적립정책";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initShopPoint(this.value);
                } else {
                    let { _id } = this.value;
                    this.form = initShopPoint((await api.console.shop.points.get({ _id }))?.point);
                    this.$emit("input", this.form);
                }
            } finally {
                this.loading = false;
            }
        },
        emit() {
            this.form = initShopPoint(this.form);
        },
        validates() {
            let { name } = this.form;
            if (!name) throw new Error("이름을 입력하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.shop.points;
                let { ...form } = this.form;
                let point = (await (this.isCreate ? post : put)(form))?.point;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", point);
                this.dialog = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .text-align-right-field {
    & > .v-input__control > .v-input__slot > .v-text-field__slot > input {
        text-align: right !important;
    }
}
::v-deep .amount-field {
    & > .v-input__control > .v-input__slot {
        padding-right: 0 !important;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner {
        margin: 1px 0 0;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner > .v-text-field--solo {
        width: 58px !important;

        .v-input__slot {
            padding-right: 4px;
        }
        .v-select__selections {
            justify-content: center;

            > .v-select__selection {
                margin-right: 0;
            }

            > input {
                display: none;
            }
        }

        .v-input__control,
        .v-input__slot {
            background-color: transparent;
        }
        .v-input__append-inner {
            margin-top: 0;
            padding-left: 0;
        }
    }
}

::v-deep .v-text-field__details {
    margin-bottom: 0 !important;
}

::v-deep .w-100-px {
    width: 100px;
}
</style>
