var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "480",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "색션 1 - 케어제품 링크"
    }
  }), _c('view-section-card', _vm._b({
    attrs: {
      "hideTitle": "",
      "hideDivider": ""
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이름"
    },
    model: {
      value: _vm.setting.main.care.text,
      callback: function ($$v) {
        _vm.$set(_vm.setting.main.care, "text", $$v);
      },
      expression: "setting.main.care.text"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "링크"
    },
    model: {
      value: _vm.setting.main.care.link,
      callback: function ($$v) {
        _vm.$set(_vm.setting.main.care, "link", $$v);
      },
      expression: "setting.main.care.link"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "설명"
    },
    model: {
      value: _vm.setting.main.care.desc,
      callback: function ($$v) {
        _vm.$set(_vm.setting.main.care, "desc", $$v);
      },
      expression: "setting.main.care.desc"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("저장하기")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }