var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "내용"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이름"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.categoryItems,
      "label": "카테고리",
      "multiple": "",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form._category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_category", $$v);
      },
      expression: "form._category"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "label": "모두선택",
      "hide-details": ""
    },
    on: {
      "change": _vm.checkAll
    },
    model: {
      value: _vm.all,
      callback: function ($$v) {
        _vm.all = $$v;
      },
      expression: "all"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "순서"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.index,
      callback: function ($$v) {
        _vm.$set(_vm.form, "index", $$v);
      },
      expression: "form.index"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "colse": "5"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "코드"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "설명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.desc,
      callback: function ($$v) {
        _vm.$set(_vm.form, "desc", $$v);
      },
      expression: "form.desc"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }