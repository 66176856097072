<template>
    <span v-if="readonly">
        <template v-if="multiple">
            {{ value.map(({ name } = {}) => name).join(", ") }}
        </template>
        <template v-else> {{ itemText(value) }} </template>
    </span>
    <v-autocomplete v-else :value="value" v-bind="{ ...$attrs, items, itemText, itemValue: '_id', multiple }" @change="(changedValue) => $emit('input', changedValue)" @click="alert">
        <template #selection="{ parent, item, attrs }" v-if="$scopedSlots['selection']">
            <slot name="selection" v-bind="{ parent, item, attrs, itemText }" />
        </template>
        <slot />
    </v-autocomplete>
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: [String, Array], default: null },
        sort: { type: [String, Object], default: () => ({ index: -1 }) },
        params: { type: [Object, Array], default: () => ({}) },
        multiple: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        items: [],
    }),
    computed: {
        item() {
            return this.items.find(({ _id }) => (this.multiple ? this.value.includes(_id) : _id == this.value));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            let sort = this.sort;
            if (typeof sort == "object") sort = JSON.stringify(sort);

            let { params } = this;
            let { levels: items } = await api.console.levels.gets({
                headers: { limit: 50, sort },
                params,
            });
            this.items = items || [];
            this.$nextTick(() => this.$emit("setLevel"));
        },
        itemText({ codeText = "-", name = "-" } = {}) {
            return `${codeText} / ${name}`;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
            if (this.$attrs.disabled && this.$attrs.disabledMessage) alert(this.$attrs.readonlyMessage);
        },
    },
};
</script>

<style></style>
