<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="배너관리" />

            <div class="caption primary--text mb-4">* PC 배너 사이즈 1920*760(px) / 모바일 배너 사이즈 720*1180(px)</div>

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template #[`item.createdAt`]="{ item }">
                    {{ item.createdAt ? item.createdAt.toDate() : "" }}
                </template>
                <template #[`item.slides`]="{ item }">
                    <v-img v-for="(slide, index) in item.slides.filter((slide) => slide.image)" :key="index" :src="slide.image" max-width="96" max-height="96" contain class="d-inline-block" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn :to="`/console/banners/${item?._id}`" x-small icon text class="mr-2">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                    <v-icon small @click="remove(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>

        <v-btn color="primary" fab fixed right bottom to="/console/banners/create"><v-icon>mdi-pencil</v-icon></v-btn>
    </v-layout>
</template>

<script>
import api from "@/api";

import ListHeading from "@/components/console/dumb/list-heading.vue";

const headers = [
    { text: "코드", width: 128, align: "center", value: "code" },
    { text: "명칭", align: "center", value: "name" },
    { text: "슬라이드", align: "start", value: "slides" },
    { text: "등록일자", align: "center", width: 140, value: "createdAt" },
    { text: "actions", align: "center", width: 100, value: "actions" },
];

export default {
    components: {
        ListHeading,
    },
    created() {
        this.init();
    },
    data: () => ({
        banners: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,
        loading: false,
    }),
    computed: {
        items() {
            return [...this.banners];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;

                var { summary, banners } = await api.console.banners.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.banners = banners;
            } finally {
                this.loading = false;
            }
        },

        close() {},

        async remove(banner) {
            if (confirm("배너를 삭제하시겠습니까?")) {
                await api.console.banners.delete(banner);
                await this.search();
            }
        },
    },
};
</script>

<style>
.console-banners-dialog .v-window__prev {
    margin-top: -50px !important;
}
.console-banners-dialog .v-window__next {
    margin-top: -50px !important;
}
.console-banners-dialog .v-carousel__controls {
    background-color: rgba(0, 0, 0, 0.57);
}
</style>
