<template>
    <span v-if="readonly">
        <template v-if="multiple">
            {{ item.map(({ name } = {}) => name).join(", ") || placeholder }}
        </template>
        <template v-else> {{ itemText(value) || placeholder }} </template>
    </span>
    <v-autocomplete v-else :value="value" v-bind="{ ...$attrs, items, itemText, itemValue: '_id', multiple }" @change="(changedValue) => $emit('input', changedValue)" @click="alert">
        <template #selection="{ parent, item, attrs }" v-if="$scopedSlots['selection']">
            <slot name="selection" v-bind="{ parent, item, attrs, itemText }" />
        </template>
        <slot />
    </v-autocomplete>
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: [String, Array], default: null },
        params: { type: [Object, Array], default: () => ({}) },
        multiple: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        placeholder: { type: String, default: null },
    },
    data: () => ({
        items: [],
    }),
    computed: {
        item() {
            if (this.multiple) return this.items.filter(({ _id }) => this.value.includes(_id));
            return this.items.find(({ _id }) => _id == this.value);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            let { params } = this;

            const parents = (await api.console.shop.categories.gets({ params }))?.categories || [];

            const children = parents.flatMap(({ children }) => children);
            const childrenGrand = children.flatMap(({ children }) => children);
            const childrenGrandGrand = childrenGrand.flatMap(({ children }) => children);
            const childrenGrandGrandGrand = childrenGrandGrand.flatMap(({ children }) => children);

            this.items = [...parents, ...childrenGrand, ...childrenGrandGrand, ...childrenGrandGrandGrand].sort();

            this.$nextTick(() => this.$emit("setCategory"));
        },
        itemText({ name }) {
            return `${name}`;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
            if (this.$attrs.disabled && this.$attrs.disabledMessage) alert(this.$attrs.readonlyMessage);
        },
    },
};
</script>

<style></style>
