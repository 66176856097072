<template>
    <view-section-card title="기본정보">
        <v-card flat>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col cols="12" md="12" class="pa-2"> <v-text-field v-model="form.name" label="이름" v-bind="{ ...attrs_input }" @input="emit" /> </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-currency-field v-model="form.value" class="amount-field text-align-right-field" v-bind="{ ...attrs_input, ...attrs_unit }" @input="emit">
                            <template #append>
                                <v-select v-model="form.unit" :items="units" item-text="suffix" dense v-bind="attrs_input__verticalTable" @input="emit" />
                            </template>
                        </v-currency-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2"> <v-currency-field v-model="form.limit" label="적립금액제한" prefix="최대" suffix="원" messages="※ 값이 0일 때, 적립금액제한 미적용" class="text-align-right-field" :disabled="disabled_limit" v-bind="{ ...attrs_input }" @input="emit" /> </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { SHOP_POINT_UNITS } from "@/assets/variables/constants";
import { attrs_input, attrs_input__verticalTable, initShopPoint } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

const units = Object.values(SHOP_POINT_UNITS);

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initShopPoint },
    },
    data: () => ({
        form: initShopPoint(),

        units,

        attrs_input,

        attrs_input__verticalTable,
    }),
    computed: {
        attrs_unit() {
            const attrs = { ...(SHOP_POINT_UNITS[this.form.unit] || {}) };

            attrs.label = "적립" + (attrs.text || "");

            delete attrs.text;
            delete attrs.value;
            delete attrs.suffix;

            return attrs;
        },
        disabled_limit() {
            return this.form.unit == SHOP_POINT_UNITS.AMOUNT.value;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        "form.unit"(unit) {
            switch (unit) {
                case SHOP_POINT_UNITS.AMOUNT.value: {
                    this.form.limit = 0;
                    this.emit();
                    break;
                }
            }
        },
    },
    methods: {
        sync() {
            this.form = initShopPoint(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
