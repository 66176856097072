var render = function render(){
  var _vm$bonusPrice, _vm$bonusPrice$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "보너스적립",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          attrs: {
            "value": true
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.bonusEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.form, "bonusEnabled", $$v);
            },
            expression: "form.bonusEnabled"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.bonusEnabled,
      expression: "form.bonusEnabled"
    }]
  }, [_c('v-divider'), _c('v-card-text', [_c('v-currency-field', {
    staticClass: "text-align-end-input ma-0",
    attrs: {
      "max": 100,
      "label": "적립퍼센트",
      "suffix": "%",
      "persistent-placeholder": "",
      "persistent-hint": "",
      "hint": `적립금액: ${(_vm$bonusPrice = _vm.bonusPrice) === null || _vm$bonusPrice === void 0 ? void 0 : (_vm$bonusPrice$format = _vm$bonusPrice.format) === null || _vm$bonusPrice$format === void 0 ? void 0 : _vm$bonusPrice$format.call(_vm$bonusPrice)}원`
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.bonus.amount,
      callback: function ($$v) {
        _vm.$set(_vm.form.bonus, "amount", $$v);
      },
      expression: "form.bonus.amount"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }