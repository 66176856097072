<template>
    <v-layout justify-center>
        <v-responsive :max-width="isMultipleList ? 1080 : 880" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading v-bind="{ title }">
                <template #add-button>
                    <display-review-form v-bind="{ code }" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n2" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </display-review-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ loading, headers, items }" :items-per-page="-1" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" style="overflow: hidden" ref="data-table">
                <template #top v-if="isMultipleList">
                    <v-tabs>
                        <v-tab exact :to="{ query: { code: undefined } }">전체</v-tab>
                        <template v-for="code in codes">
                            <v-tab exact :to="{ query: { ...$route.query, code: code.value } }" :key="code.value">{{ code.text }}</v-tab>
                        </template>
                    </v-tabs>
                    <v-divider />
                </template>

                <template #body="props">
                    <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" :disabled="!code || loading" @change="setNewIndex">
                        <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
                    </draggable>
                </template>

                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.product`]="{ value }">
                    <item-product :product="value" isLink />
                    <template v-if="value?.review__best">
                        <v-divider />
                        <item-review :review="value?.review__best" showsUser flat color="transparent" />
                    </template>
                </template>

                <template #[`item.duration`]="{ item }">
                    {{ item?.startsAt?.toDateTime?.() || "시작일시 없음" }} <br />
                    ~ {{ item?.endsAt?.toDateTime?.() || "종료일시 없음" }}
                </template>

                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" v-bind="attrs_switch" @change="(shows) => update({ _id: item._id, shows })" />
                </template>

                <template #[`item.actions`]="{ item }">
                    <display-review-form v-bind="{ _id: item._id, code }" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </display-review-form>
                    <v-btn text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch, DISPLAY_CODES } from "@/assets/variables";

import Draggable from "vuedraggable";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import DisplayReviewForm from "@/components/console/shop/displays/display-review-form.vue";
import ItemProduct from "@/components/console/dumb/item-product.vue";
import ItemReview from "@/components/console/dumb/item-review.vue";

const codes = Object.values(DISPLAY_CODES);

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,

        ImagePopup,
        ListHeading,
        DisplayReviewForm,
        ItemProduct,
        ItemReview,
    },
    data: () => ({
        displays: [],

        limit: 10,
        summary: { totalCount: 0 },

        codes,
        attrs_switch,

        loading: false,
    }),
    computed: {
        isMultipleList() {
            return !this.$route.params?.code;
        },
        code() {
            return this.$route.params?.code || this.$route.query.code || null;
        },

        title() {
            return this.isMultipleList ? "리뷰전시" : DISPLAY_CODES[this.code]?.text;
        },
        headers() {
            const headers = [];

            headers.push({ width: +60, text: "#", value: "index", align: "end" });
            if (this.isMultipleList) headers.push({ width: 200, text: "위치", value: "code", formatter: (value) => DISPLAY_CODES[value]?.text || "-" });
            headers.push({ width: 440, text: "상품 / 리뷰", value: "product", cellClass: "max-width-0 px-0 text-truncate" });
            headers.push({ width: 170, text: "전시기간", value: "duration", cellClass: "line-height-1-5" });
            headers.push({ width: +80, text: "노출", value: "shows" });
            headers.push({ width: 100, text: "", value: "actions", align: "center", cellClass: "px-0" });

            return headers.map((item, index, array) => ({
                ...item,
                divider: index != array.length - 1,
                cellClass: item.cellClass + ` caption`,
                formatter: item.formatter ?? ((value) => value ?? "-"),
            }));
        },

        items() {
            return [...this.displays];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.code = this.code;
            if (!query.code) delete query.code;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, displays } = await api.console.shop.displays.gets({
                    headers: { skip, limit },
                    params,
                });

                this.displays = displays;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(form) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { display } = await api.console.shop.displays.put(form);
                this.updateItem(display);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        async remove(display) {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (confirm("해당 상품전시를 삭제하시겠습니까?")) {
                    await api.console.shop.displays.delete(display);
                    await this.search();
                }
            } finally {
                this.loading = false;
            }
        },

        updateItem(item) {
            const index = this.displays.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.displays.splice(index, 1, item);
            else {
                this.displays = [item, ...this.displays];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async setNewIndex({ moved = {} }) {
            let {
                element: { _id, index },
                oldIndex,
                newIndex,
            } = moved;
            index += oldIndex - newIndex;
            let form = { _id, index };
            this.update(form);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .line-height-1-5 {
        line-height: 1.5;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__mobile-row__cell {
        max-width: calc(100vw - 12px - 16px - 80px - 16px - 12px);
    }
}
</style>
