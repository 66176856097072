<template>
    <view-section-card title="유튜브 코드" hideDivider v-bind="{ loading }">
        <v-sheet>
            <v-card-text>
                <v-row>
                    <v-col cols="6" class="py-0">
                        <v-text-field v-model="youtubeCode" v-bind="attrs_input" label="코드" persistent-placeholder class="pt-0" @change="input" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-sheet>
    </view-section-card>
</template>

<script>
import { attrs_input, attrs_switch } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: null },
        loading: { type: Boolean, default: false },
    },
    data: () => {
        return {

            youtubeCode: null,

            attrs_switch,
            attrs_input,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            try {
                var { discountEnabled, discount } = this.value;

                if (discountEnabled === undefined) discountEnabled = this.discountEnabled;
                if (discount === undefined) discount = this.discount;

                if (discount.expiration.startDate) discount.expiration.startDate = discount.expiration.startDate.toDate();
                if (discount.expiration.endDate) discount.expiration.endDate = discount.expiration.endDate.toDate();
                Object.assign(this.$data, {
                    discountEnabled,
                    discount,
                });
            } catch (error) {
                console.error(error);
            }
        },

        input() {
            this.$set(this.value, "youtubeCode", this.youtubeCode);
            this.$emit("input", this.value);
        },
    },
};
</script>
