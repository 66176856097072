<template>
    <view-section-card title="적용된 배송정책" v-bind="{ loading }">
        <template #actions>
            <v-btn to="/console/shop/shippings" small text tile height="54" class="mr-n4 my-n1"> 배송정책 설정하기 <v-icon>mdi-chevron-right</v-icon> </v-btn>
        </template>
        <template v-if="loading">
            <v-sheet height="48" color="transparent" class="pa-3 d-flex align-center">
                <span> 로드중입니다 </span>
            </v-sheet>
        </template>
        <template v-else-if="shipping">
            <form-options :value="shipping" mode="view" />
        </template>
        <template v-else>
            <v-sheet height="48" color="transparent" class="pa-3 d-flex align-center">
                <span> 연결된 배송정보가 없습니다 </span>
            </v-sheet>
        </template>
    </view-section-card>
</template>


<script>
import api from "@/api";
import { initProduct, SHIPPING_TARGET_TYPES } from "@/assets/variables";

import FormOptions from "@/components/console/shop/shippings/form/form-options.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        FormOptions,
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        shipping__site: null,
    }),
    computed: {
        shipping() {
            return this.value?.shipping || this.shipping__site;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
        loading() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            if (!this.value._id) return;
            if (this.value.shipping) return;

            this.shipping__site = (
                await api.v1.shop.shippings.gets({
                    headers: { mode: "item" },
                    params: { targetType: SHIPPING_TARGET_TYPES.EVERY_ASSET.value },
                })
            )?.shipping;
        },
    },
};
</script>
