<template>
    <view-section-card title="사용">
        <template #actions> <v-switch v-model="form.usage.isActive" v-bind="attrs_switch" @change="emit" /> </template>
        <v-card :disabled="!form.usage.isActive" flat>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col cols="12" md="12" class="pa-2">
                        <form-usage-dates v-model="form" @input="emit" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { attrs_switch, initShopPoint } from "@/assets/variables";

import FormUsageDates from "./form-usage-dates.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        FormUsageDates,
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initShopPoint },
    },
    data: () => ({
        form: initShopPoint(),

        attrs_switch,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopPoint(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
