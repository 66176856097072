<template>
    <v-row no-gutters>
        <template v-for="(item, index) in form.charge.pricesRange">
            <v-col cols="12" :key="`${index}-input`">
                <v-row no-gutters align="center">
                    <v-col>
                        <v-currency-field v-model="item.price" suffix="원" class="caption" v-bind="{ ...attrs_input__verticalTable, readonly }" @input="emit" />
                    </v-col>
                    <v-col cols="auto"> 이상 구매시 </v-col>
                    <v-col>
                        <v-currency-field v-model="item.amount" suffix="원" class="caption" v-bind="{ ...attrs_input__verticalTable, readonly }" @input="emit" />
                    </v-col>
                    <template v-if="mode == 'form'">
                        <v-col cols="auto" align-self="stretch"> <v-divider vertical /> </v-col>
                        <v-col cols="auto">
                            <v-btn text icon tile color="red" height="48" @click="pull(item)">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </v-col>
                    </template>
                </v-row>
            </v-col>
            <v-col cols="12" :key="`${index}-divider`" v-if="index != form.charge.pricesRange.length - 1"> <v-divider style="margin-bottom: -1px" /> </v-col>
        </template>
        <template v-if="mode == 'form'">
            <v-col cols="12" v-if="form.charge.pricesRange.length"> <v-divider style="margin-bottom: -1px" /> </v-col>
            <v-col cols="12">
                <v-btn text block tile height="48" color="primary" @click="push">
                    항목추가
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </template>
        <template v-if="mode != 'form'">
            <template v-if="!form.charge.pricesRange.length">
                <v-sheet height="48" color="transparent" class="pa-3 d-flex align-center">
                    <span> 요금항목이 없습니다 </span>
                </v-sheet>
            </template>
        </template>
    </v-row>
</template>

<script>
import { attrs_input__verticalTable, initShopShippingOption, initShopShippingOption__pricesRangeItem, SHIPPING_OPTION_CHARGE_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopShippingOption },

        mode: { type: String, default: "form" },
    },
    data: () => ({
        form: initShopShippingOption(),

        attrs_input__verticalTable,
        SHIPPING_OPTION_CHARGE_TYPES,
    }),
    computed: {
        readonly() {
            return this.mode != "form";
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopShippingOption(this.value);
        },
        emit() {
            this.$emit("input", initShopShippingOption(this.form));
        },
        push() {
            this.form.charge.pricesRange.push(initShopShippingOption__pricesRangeItem());
            this.emit();
        },
        pull(item) {
            const index = this.form.charge.pricesRange.findIndex(({ tempId }) => tempId == item.tempId);
            if (0 <= index) {
                this.form.charge.pricesRange.splice(index, 1);
            }
            this.emit();
        },
    },
};
</script>