var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.areas__available,
      callback: function ($$v) {
        _vm.$set(_vm.form, "areas__available", $$v);
      },
      expression: "form.areas__available"
    }
  }, 'v-select', Object.assign({}, _vm.$attrs, {
    items: _vm.items
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }