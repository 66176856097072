<template>
    <list-search v-bind="{ loading, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" lg="6" class="pa-2">
                <v-text-field v-model="query['name']" label="정책명" v-bind="attrs_input" clearable @keydown.enter="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-product v-model="query['target._products']" label="대상상품" v-bind="attrs_input" clearable @input="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select v-model="query['isActive']" label="사용여부" :items="isActiveItems" v-bind="attrs_input" @input="emit" />
            </v-col>
            <v-col cols="12" class="pa-2">
                <shop-category-select v-model="query['target._categories']" v-bind="{ ...attrs_input, categories }" clearable @input="emit" />
            </v-col>
            <v-col cols="12" class="pa-2">
                <v-row align="center" class="ma-n2">
                    <v-col cols="12" sm="6" lg="3" class="pa-2">
                        <v-text-field label="기간검색(시작일)" v-model="query['searchDateValue'][0]" type="date" max="2999-12-31" clearable v-bind="attrs_input" @input="emit" />
                    </v-col>
                    <v-col cols="12" sm="6" lg="3" class="pa-2">
                        <v-text-field label="기간검색(종료일)" v-model="query['searchDateValue'][1]" type="date" max="2999-12-31" clearable v-bind="attrs_input" @input="emit" />
                    </v-col>
                    <v-col class="pa-2">
                        <dates-toggle v-model="query.searchDateValue" @input="emit" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import DatesToggle from "@/components/console/dumb/dates-toggle.vue";
import ShopCategorySelect from "@/components/console/shop/categories/shop-category-select.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["name"]: query?.["name"] || null,
    ["isActive"]: JSON.parse(query?.["isActive"] || "null"),

    ["searchDateValue"]: [...(query["searchDateValue"] ?? [null, null])],

    ["target._products"]: query?.["target._products"] || null,
    ["target._categories"]: query?.["target._categories"] || null,
});

const isActiveItems = [
    { text: "전체", value: null },
    { text: "사용 ", value: true },
    { text: "미사용", value: false },
];

export default {
    components: {
        ListSearch,
        DatesToggle,
        ShopCategorySelect,
        AutocompleteProduct,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        categories: [],

        isActiveItems,

        attrs_input,
    }),
    mounted() {
        this.init().then(this.sync);
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        async init() {
            const { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query["name"]) delete query["name"];
            if (query["isActive"] == null) delete query["isActive"];

            if (!query["searchDateValue"][0] && !query["searchDateValue"][1]) delete query["searchDateValue"];

            if (!query["target._products"]) delete query["target._products"];
            if (!query["target._categories"]) delete query["target._categories"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
