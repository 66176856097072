<template>
    <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" ref="data-table" mobile-breakpoint="610">
        <template #body="props">
            <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" :sort="!loading || items.length < 2" @change="setNewIndex">
                <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
            </draggable>
        </template>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
        <template #[`item.handle`]>
            <v-btn text tile color="grey darken-1" height="48" style="cursor: move" :disabled="items.length < 2">
                <v-icon>mdi-drag</v-icon>
            </v-btn>
        </template>
        <template #[`item.text`]="{ item }">
            <v-text-field v-model="item.text" v-bind="attrs_input__verticalTable" @input="emit" />
        </template>
        <template #[`item.value`]="{ item }">
            <v-text-field v-model="item.value" :disabled="item.disabled" v-bind="attrs_input__verticalTable" @input="emit" />
        </template>
        <template #[`item.color`]="{ item }">
            <v-text-field v-model="item.color" label="HEXA" clearable v-bind="attrs_input__verticalTable" @input="emit">
                <template #append>
                    <display-list-codes-form-color v-model="item.color" @input="emit">
                        <template #activator="{ attrs, on }">
                            <v-card flat outlined width="24" height="24" rounded="xl" :color="item.color" class="ma-0" v-bind="attrs" v-on="on"> </v-card>
                        </template>
                    </display-list-codes-form-color>
                </template>
            </v-text-field>
        </template>
        <template #[`item.shows`]="{ item }">
            <v-switch v-model="item.shows" :disabled="item.disabled" v-bind="attrs_switch" @input="emit" />
        </template>
        <template #[`item.actions`]="{ item, index }">
            <v-btn text tile height="48" color="red" :disabled="item.disabled" @click="pull(index)"> <v-icon>mdi-minus</v-icon> </v-btn>
        </template>
        <template #footer>
            <v-divider />
            <v-row no-gutters align="center">
                <v-spacer />
                <v-divider vertical />
                <v-col cols="auto">
                    <v-btn text tile height="58" color="primary" @click="push"> <v-icon>mdi-plus</v-icon> </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { attrs_input__verticalTable, attrs_switch, initSettingShopDisplayCode } from "@/assets/variables";

import Draggable from "vuedraggable";
import DisplayListCodesFormColor from "./display-list-codes-form-color.vue";

const headers = [
    { width: +64, text: "", value: "handle", align: "center" },
    { width: 170, text: "이름", value: "text" },
    { width: 170, text: "코드", value: "value" },
    { width: 169, text: "컬러", value: "color" },
    { width: +80, text: "노출", value: "shows", cellClass: "" },
    { width: +64, text: "", value: "actions" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, cellClass: item.cellClass ?? "px-0" }));

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,
        DisplayListCodesFormColor,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        items: [],

        headers,
        attrs_switch,
        attrs_input__verticalTable,

        loading: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.items = [...this.value];
        },
        emit() {
            this.$emit("input", this.items);
        },
        pull(index) {
            this.items.splice(index, 1);
            this.emit();
        },
        push() {
            this.items.push(initSettingShopDisplayCode());
            this.emit();
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let { oldIndex, newIndex } = moved;
                this.items.splice(newIndex, 0, ...this.items.splice(oldIndex, 1));
                this.emit();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

