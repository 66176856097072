<template>
    <v-dialog v-model="shows" width="360" :fullscreen="$vuetify.breakpoint.mobile">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card tile>
            <v-card-title> 색상 선택 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2">
                        <v-card outlined class="fill-height">
                            <v-card-text style="position: relative">
                                <span class="d-inline-block white caption pa-1" style="position: absolute; top: 0; left: 8px; transform: translateY(-50%)">색상</span>
                                <v-card flat style="overflow: hidden">
                                    <v-responsive :aspect-ratio="1 / 1" height="294" style="overflow-y: auto">
                                        <v-color-picker v-model="color" @update:color="changeColor" hide-canvas hide-sliders hide-inputs show-swatches flat width="100%" swatches-max-height="100%" />
                                    </v-responsive>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장하기</v-btn>
            </v-card-actions>
        </v-card>

        <v-fade-transition>
            <v-overlay v-show="loading" absolute light color="white">
                <v-progress-circular indeterminate size="100" width="5" color="primary" />
            </v-overlay>
        </v-fade-transition>
    </v-dialog>
</template>

<script>
import { attrs_input } from "@/assets/variables";

export default {
    props: {
        value: { type: String },
    },
    data: () => ({
        color: null,
        shows: false,
        loading: false,

        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.color = this.value;
        },
        save() {
            this.$emit("input", this.color);
            this.shows = false;
        },
        changeColor({ hexa } = {}) {
            this.color = hexa;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-color-picker__swatches > div {
        padding: 0;
    }

    .v-color-picker__swatch {
        margin: 0;
    }
    .v-color-picker__color {
        margin: 2px;
        width: 22px;
        height: 22px;
        max-height: 22px;
        border-radius: 50%;
    }
}
</style>
