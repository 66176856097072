var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', _vm._g({
    attrs: {
      "return-value": _vm.form.state,
      "cancel-text": "취소",
      "save-text": "변경",
      "large": "",
      "eager": "",
      "persistent": ""
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.form, "state", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.form, "state", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-select', _vm._b({
          staticClass: "mt-3",
          model: {
            value: _vm.form.state,
            callback: function ($$v) {
              _vm.$set(_vm.form, "state", $$v);
            },
            expression: "form.state"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input, {
          items: _vm.items
        }), false)), _c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }],
          attrs: {
            "absolute": "",
            "light": "",
            "color": "white"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": "",
            "size": "100",
            "width": "5",
            "color": "primary"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, {
    save: _vm.save
  }), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }