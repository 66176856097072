<template>
    <v-layout justify-center>
        <v-responsive max-width="960" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="코드 목록">
                <template #add-button>
                    <code-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </code-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.isActive`]="{item}">
                    <v-switch v-model="item.isActive" v-bind="attrs_switch" @change="(isActive) => update({ _id: item._id, isActive })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <code-form v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </code-form>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import CodeForm from "@/components/console/shop/codes/code-form.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import { attrs_switch, SHOP_CODE_TYPES } from "@/assets/variables";

const headers = [
    { divider: true, width: +80, text: "#", value: "index" },
    { divider: true, width: 120, text: "유형", value: "type", formatter: (value) => SHOP_CODE_TYPES[value]?.text || value },
    { divider: true, width: 150, text: "코드", value: "code" },
    { divider: true, width: 220, text: "추천인", value: "promoter", formatter: (value) => (value ? `${value.username} / ${value.name}` : "-") },
    { divider: true, width: 170, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { divider: true, width: 100, text: "활성화", value: "isActive" },
    { width: +88, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        CodeForm,
        ListHeading,
    },
    data() {
        return {
            codes: [],

            limit: 20,
            summary: { totalCount: 0 },

            headers,
            attrs_switch,
            loading: false,
        };
    },
    computed: {
        items() {
            return this.codes.map((item, index) => ({ ...item, index: index + 1 }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, codes } = await api.console.shop.codes.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.codes = codes.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(code) {
            try {
                code = (await api.console.shop.codes.put(code))?.code;
                this.updateItem(code);
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(code) {
            const go = confirm(`"${code?.name}" 상품을 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ code }] = [await api.console.shop.codes.delete(code)];
                    this.codes = this.codes.filter(({ _id }) => _id !== code._id);
                    alert(`"${code?.name}" 상품이 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(code) {
            const index = this.codes.findIndex(({ _id }) => _id == code._id);
            if (0 <= index) this.codes.splice(index, 1, code);
            else {
                this.codes = [code, ...this.codes];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
