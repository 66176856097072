<template>
    <v-combobox v-model="form.hashtags" :search-input.sync="text" label="해시태그" multiple :dense="false" append-icon="" v-bind="attrs_input" @input="emit" @keydown="push">
        <template #selection="{ attrs, item, parent }">
            <v-chip v-bind="attrs" outlined text label small close @click:close="parent.selectItem(item)"> {{ item }} </v-chip>
        </template>
    </v-combobox>
</template>

<script>
import { attrs_input } from "@/assets/variables/attrs";

import { initProduct } from "@/assets/variables/inits";

export default {
    props: {
        value: { type: Object, default: initProduct },
    },
    setup: () => ({
        attrs_input,
    }),
    data: () => ({
        form: initProduct(),
        text: null,
    }),
    methods: {
        sync() {
            this.form = initProduct(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        push(/** @type {KeyboardEvent} */ event) {
            if (event.ctrlKey && event.code == "KeyV") {
                setTimeout(() => {
                    const items = (this.text || "").split(",").reduce((items, text) => {
                        text = text.trim();
                        if (text) items.push(text);
                        return items;
                    }, []);
                    this.form.hashtags = [...new Set([...this.form.hashtags, ...items])];
                    this.text = null;
                    this.emit();
                }, 100);
            }
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>