var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', _vm._b({
    attrs: {
      "search-input": _vm.text,
      "label": "해시태그",
      "multiple": "",
      "dense": false,
      "append-icon": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.text = $event;
      },
      "update:search-input": function ($event) {
        _vm.text = $event;
      },
      "input": _vm.emit,
      "keydown": _vm.push
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', _vm._b({
          attrs: {
            "outlined": "",
            "text": "",
            "label": "",
            "small": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }]),
    model: {
      value: _vm.form.hashtags,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hashtags", $$v);
      },
      expression: "form.hashtags"
    }
  }, 'v-combobox', _vm.attrs_input, false));

}
var staticRenderFns = []

export { render, staticRenderFns }