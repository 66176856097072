<template>
    <v-edit-dialog :return-value.sync="item.custom.name" large cancel-text="취소" save-text="저장" v-on="{ save }">
        <slot />
        <template #input>
            <v-sheet>
                <v-text-field v-model="item.custom.name" class="mt-3" clearable v-bind="{ ...attrs_input, placeholder }" />
            </v-sheet>
        </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import { initShopProductRelated } from "@/assets/variables/inits";

export default {
    props: {
        value: { type: Object, default: initShopProductRelated },
        placeholder: { type: String, default: null },
    },
    data: () => ({
        item: initShopProductRelated(),
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = initShopProductRelated(this.value);
        },
        save() {
            let {
                _id,
                tempId,
                custom: { name },
            } = this.item;

            let form = { _id, tempId, custom: { ...(this.value?.custom || {}), name } };

            this.$emit("update", form);
        },
    },
};
</script>

