var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "발급대상",
      "items": _vm.targetTypes
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.issue.target.type,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.target, "type", $$v);
      },
      expression: "form.issue.target.type"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.issue.target.type == _vm.ISSUE_TARGET_TYPES.USERS.value,
      expression: "form.issue.target.type == ISSUE_TARGET_TYPES.USERS.value"
    }]
  }, [_c('div', {
    staticClass: "pa-2"
  }, [_c('autocomplete-user', _vm._b({
    attrs: {
      "label": '발급대상 ' + _vm.ISSUE_TARGET_TYPES.USERS.text,
      "multiple": "",
      "dense": false
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var parent = _ref.parent,
          item = _ref.item,
          attrs = _ref.attrs,
          itemText = _ref.itemText;
        return [_c('v-chip', _vm._b({
          key: item._id,
          attrs: {
            "outlined": "",
            "label": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(_vm._s(itemText(item)))])];
      }
    }]),
    model: {
      value: _vm.form.issue.target._users,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.target, "_users", $$v);
      },
      expression: "form.issue.target._users"
    }
  }, 'autocomplete-user', _vm.attrs_input, false))], 1)])]), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.issue.target.type == _vm.ISSUE_TARGET_TYPES.USER_TYPES.value,
      expression: "form.issue.target.type == ISSUE_TARGET_TYPES.USER_TYPES.value"
    }]
  }, [_c('div', {
    staticClass: "pa-2"
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": '발급대상 ' + _vm.ISSUE_TARGET_TYPES.USER_TYPES.text,
      "items": _vm.userTypes,
      "multiple": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.issue.target.userTypes,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.target, "userTypes", $$v);
      },
      expression: "form.issue.target.userTypes"
    }
  }, 'v-select', _vm.attrs_input, false))], 1)])]), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.issue.target.type == _vm.ISSUE_TARGET_TYPES.USER_LEVELS.value,
      expression: "form.issue.target.type == ISSUE_TARGET_TYPES.USER_LEVELS.value"
    }]
  }, [_c('div', {
    staticClass: "pa-2"
  }, [_c('autocomplete-user-level', _vm._b({
    attrs: {
      "label": '발급대상 ' + _vm.ISSUE_TARGET_TYPES.USER_LEVELS.text,
      "multiple": "",
      "dense": false
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref2) {
        var parent = _ref2.parent,
          item = _ref2.item,
          attrs = _ref2.attrs,
          itemText = _ref2.itemText;
        return [_c('v-chip', _vm._b({
          key: item._id,
          attrs: {
            "outlined": "",
            "label": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(_vm._s(itemText(item)))])];
      }
    }]),
    model: {
      value: _vm.form.issue.target._levels,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.target, "_levels", $$v);
      },
      expression: "form.issue.target._levels"
    }
  }, 'autocomplete-user-level', _vm.attrs_input, false))], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }