<template>
    <v-select v-model="form.charge.type" v-bind="{ ...attrs_input__verticalTable, items, readonly, appendIcon }" class="caption" color="transparent" @input="emit" />
</template>

<script>
import { attrs_input__verticalTable, initShopShippingOption, SHIPPING_OPTION_CHARGE_TYPES } from "@/assets/variables";

const items = Object.values(SHIPPING_OPTION_CHARGE_TYPES);

export default {
    props: {
        value: { type: Object, default: initShopShippingOption },

        mode: { type: String, default: "form" },
    },
    data: () => ({
        form: initShopShippingOption(),

        items,

        attrs_input__verticalTable,
    }),
    computed: {
        readonly() {
            return this.mode != "form";
        },
        appendIcon() {
            return this.mode != "form" ? "" : undefined;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopShippingOption(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>