<template>
    <v-dialog v-model="dialog" width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="12"> <v-select v-model="form.type" label="유형" :items="types" v-bind="{ ...attrs_input, loading }" disabled /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.code" label="코드" placeholder="자동생성" v-bind="{ ...attrs_input, loading }" /> </v-col>
                    <v-col cols="12"> <autocomplete-user v-model="form._promoter" label="추천인" v-bind="{ ...attrs_input, loading }" :params="params_autocompleteUser" :disabled="!isCreate" ref="autocomplete-user" /> </v-col>
                    <v-col cols="12"> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.isActive" dense v-bind="attrs_switch">
                        <span slot="label" class="subtitle-2"> {{ form.isActive ? "활성화" : "비활성화" }} </span>
                    </v-switch>
                </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch, SHOP_CODE_TYPES, USER_TYPES } from "@/assets/variables";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

const initCode = () => ({
    type: SHOP_CODE_TYPES.PROMOTER.value,

    _promoter: null,

    code: null,
    isActive: false,
});

const types = Object.values(SHOP_CODE_TYPES);

const params_autocompleteUser = {
    type: USER_TYPES.PROMOTER.value,
};

export default {
    components: {
        AutocompleteUser,
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initCode(),

        dialog: false,
        loading: false,

        types,
        params_autocompleteUser,
        attrs_input,
        attrs_switch,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "코드";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            this.loading = true;
            try {
                if (this.isCreate) this.form = initCode();
                else {
                    let { _id } = this;
                    this.form = (await api.console.shop.codes.get({ _id }))?.code;
                    this.$emit("input", { ...this.form });
                }
                this.$refs["autocomplete-user"]?.init?.();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { code } = this.form;
            if (!this.isCreate && (!code || code.replace(/\s/, "").length == 0)) throw new Error("코드명을 작성해주세요");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.shop.codes;
                let code = (await (this.isCreate ? post : put)(this.form))?.code;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", code);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
