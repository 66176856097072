<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%">
            <list-heading title="쿠폰정책" v-model="showsSearch" showsFilterButton>
                <template #add-button>
                    <shop-coupon-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </shop-coupon-form>
                </template>
            </list-heading>

            <shop-coupon-search v-bind="{ showsSearch, loading }" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.coupon`]="{ item }">
                    <item-coupon :coupon="item" flat tile color="transparent" />
                </template>
                <template #[`item.usage.target`]="{ item }">
                    <item-usage-target :usage="item.usage" flat tile color="transparent" />
                </template>
                <template #[`item.usage.limit`]="{ item }">
                    <item-usage-limit :usage="item.usage" flat tile color="transparent" />
                </template>
                <template #[`item.usage.datetime`]="{ item }">
                    <item-usage-datetime :usage="item.usage" flat tile color="transparent" />
                </template>

                <template #[`item.issue`]="{ item }">
                    <item-issue :issue="item.issue" flat tile color="transparent" />
                </template>
                <template #[`item.issue.target`]="{ item }">
                    <item-issue-target :issue="item.issue" flat tile color="transparent" />
                </template>
                <template #[`item.issue.limit`]="{ item }">
                    <item-issue-limit :issue="item.issue" flat tile color="transparent" />
                </template>
                <template #[`item.issue.datetime`]="{ item }">
                    <item-issue-datetime :issue="item.issue" flat tile color="transparent" />
                </template>
                <template #[`item.meta.count`]="{ value }">
                    {{ value.used.format() }} /
                    {{ value.total.format() }}
                </template>

                <template #[`item.issue.isActive`]="{ item }">
                    <v-switch v-model="item.issue.isActive" v-bind="attrs_switch" @change="(isActive) => update({ _id: item._id, issue: { ...item.issue, isActive } })" />
                </template>

                <template #[`item.usage.isActive`]="{ item }">
                    <v-switch v-model="item.usage.isActive" v-bind="attrs_switch" @change="(isActive) => update({ _id: item._id, usage: { ...item.usage, isActive } })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-layout justify-center>
                        <shop-coupon-form :value="item" @input="updateItem">
                            <template #activator="{ attrs, on }">
                                <v-btn small text icon tile v-bind="attrs" v-on="on">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                        </shop-coupon-form>
                        <v-btn small text icon tile title="발급쿠폰목록" :to="{ path: '/console/user-coupons/', query: { _shopCoupon: item._id } }">
                            <v-icon small>mdi-ticket-percent</v-icon>
                        </v-btn>
                    </v-layout>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch, initShopCoupon } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import ItemCoupon from "@/components/console/shop/coupons/list/item-coupon.vue";

import ItemUsageLimit from "@/components/console/shop/coupons/list/item-usage-limit.vue";
import ItemUsageTarget from "@/components/console/shop/coupons/list/item-usage-target.vue";
import ItemUsageDatetime from "@/components/console/shop/coupons/list/item-usage-datetime.vue";

import ItemIssue from "@/components/console/shop/coupons/list/item-issue.vue";
import ItemIssueLimit from "@/components/console/shop/coupons/list/item-issue-limit.vue";
import ItemIssueTarget from "@/components/console/shop/coupons/list/item-issue-target.vue";
import ItemIssueDatetime from "@/components/console/shop/coupons/list/item-issue-datetime.vue";

import ShopCouponForm from "@/components/console/shop/coupons/form/shop-coupon-form.vue";
import ShopCouponSearch from "@/components/console/shop/coupons/list/shop-coupon-search.vue";

const headers = [
    { width: 160, text: "쿠폰", value: "coupon" },
    { width: 140, text: "사용대상", value: "usage.target" },
    { width: 140, text: "사용제한", value: "usage.limit" },
    { width: 140, text: "사용기간", value: "usage.datetime" },
    { width: 140, text: "발급형태", value: "issue" },
    { width: 140, text: "발급대상", value: "issue.target" },
    { width: 140, text: "발급제한", value: "issue.limit" },
    { width: 140, text: "발급기간", value: "issue.datetime" },
    { width: +80, text: "사용량 / 발급량", value: "meta.count", cellClass: "py-3", align: "center" },
    { width: +80, text: "발급", value: "issue.isActive", cellClass: "py-3", align: "center" },
    { width: +80, text: "사용", value: "usage.isActive", cellClass: "py-3", align: "center" },
    { width: 100, text: "생성일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-", cellClass: "py-3" },
    { width: 100, text: "", value: "actions", cellClass: "py-3" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "pa-0") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ListHeading,

        ItemCoupon,

        ItemUsageLimit,
        ItemUsageTarget,
        ItemUsageDatetime,

        ItemIssue,
        ItemIssueLimit,
        ItemIssueTarget,
        ItemIssueDatetime,

        ShopCouponForm,
        ShopCouponSearch,
    },
    data: () => ({
        coupons: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,
        attrs_switch,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return [...this.coupons].map(initShopCoupon);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.coupons = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, coupons } = await api.console.shop.coupons.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.coupons = coupons;
            } finally {
                this.loading = false;
            }
        },

        async update(coupon) {
            if (this.loading) return;
            else this.loading = true;
            try {
                coupon = (await api.console.shop.coupons.put(coupon))?.coupon;
                this.updateItem(coupon);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(coupon) {
            const index = this.coupons.findIndex(({ _id }) => _id == coupon._id);
            if (0 <= index) this.coupons.splice(index, 1, coupon);
            else {
                this.coupons = [initShopCoupon(coupon), ...this.coupons];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
