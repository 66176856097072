<template>
    <list-search v-bind="{ loading, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" md="6" lg="" class="pa-2">
                <autocomplete-user v-model="query['_user']" label="회원" placeholder="전체" :sort="{ name: 1, createdAt: 1, _id: 1 }" :itemText="({ name, username }) => `${name || '-'} (${username || '-'})`" clearable v-bind="attrs_input" @input="emit" />
            </v-col>
            <v-col cols="12" md="6" lg="" class="pa-2">
                <v-text-field v-model="query['code']" label="결제번호" clearable v-bind="attrs_input" @keydown.enter="emit" />
            </v-col>
            <v-col cols="12" md="6" lg="" class="pa-2">
                <v-text-field v-model="query['name']" label="결제명" clearable v-bind="attrs_input" @keydown.enter="emit" />
            </v-col>
            <v-col cols="12" md="6" lg="" class="pa-2">
                <v-select v-model="query['paymentAt']" label="결제여부" :items="paymentAtItems" v-bind="attrs_input" @input="emit" />
            </v-col>
            <v-col cols="12" md="6" lg="" class="pa-2">
                <v-select v-model="query['shows']" label="표시여부" :items="showsItems" v-bind="attrs_input" @input="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["_user"]: query?.["_user"] || null,
    ["code"]: query?.["code"] || null,
    ["name"]: query?.["name"] || null,
    ["shows"]: JSON.parse(query?.["shows"] || "null"),
    ["paymentAt"]: JSON.parse(query?.["paymentAt"] || "null"),
});

const paymentAtItems = [
    { text: "전체", value: null },
    { text: "미결제 ", value: false },
    { text: "결제완료", value: true },
];

const showsItems = [
    { text: "전체", value: null },
    { text: "전시", value: true },
    { text: "숨김 ", value: false },
];

export default {
    components: {
        ListSearch,
        AutocompleteUser,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    setup: () => ({
        paymentAtItems,
        showsItems,
        attrs_input,
    }),
    data: () => ({
        query: initQuery(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query?.["_user"]) delete query["_user"];
            if (!query?.["code"]) delete query["code"];
            if (!query?.["name"]) delete query["name"];
            if (query?.["shows"] == null) delete query["shows"];
            if (query?.["paymentAt"] == null) delete query["paymentAt"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
