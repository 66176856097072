var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%"
    }
  }, [_c('list-heading', _vm._b({
    attrs: {
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }, 'list-heading', {
    title: _vm.title
  }, false)), _c('comment-list-search', _vm._b({}, 'comment-list-search', {
    showsSearch: _vm.showsSearch,
    loading: _vm.loading,
    boardCode: _vm.boardCode,
    boardCategory: _vm.boardCategory
  }, false)), _vm.categories.length ? [_c('v-row', {
    staticClass: "mt-n4"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('comment-category-toggle', _vm._b({}, 'comment-category-toggle', {
    categories: _vm.categories
  }, false))], 1)], 1)] : _vm._e(), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            attrs: {
              "title": header.withTitle ? header.formatter.bind(item)(value, item) : undefined
            },
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.isDeleted`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (isDeleted) {
              return _vm.update({
                _id: item._id,
                isDeleted
              });
            }
          },
          model: {
            value: item.isDeleted,
            callback: function ($$v) {
              _vm.$set(item, "isDeleted", $$v);
            },
            expression: "item.isDeleted"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      }
    }, {
      key: `item.board`,
      fn: function (_ref3) {
        var item = _ref3.item,
          value = _ref3.value;
        return [_c('v-row', [value ? _c('v-col', {
          staticClass: "py-0 text-truncate"
        }, [_vm._v(" " + _vm._s(value === null || value === void 0 ? void 0 : value.subject) + " ")]) : _c('v-col', {
          staticClass: "py-0 text--secondary"
        }, [_vm._v(" 삭제된 게시글입니다 ")]), _c('v-col', {
          staticClass: "px-1 py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "icon": "",
            "text": "",
            "to": {
              path: `/console/boards/${value === null || value === void 0 ? void 0 : value._id}`,
              query: {
                code: value.code,
                category: value.category
              }
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-link")])], 1)], 1), _c('v-col', {
          staticClass: "pl-1 py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "icon": "",
            "text": "",
            "to": {
              query: Object.assign({}, _vm.$route.query, {
                _board: value === null || value === void 0 ? void 0 : value._id,
                page: null
              })
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-check")])], 1)], 1)], 1)];
      }
    }, {
      key: `item.writer`,
      fn: function (_ref4) {
        var item = _ref4.item,
          value = _ref4.value;
        return [_c('v-row', [value ? _c('v-col', {
          staticClass: "py-0"
        }, [_vm._v(" " + _vm._s(value === null || value === void 0 ? void 0 : value.name) + " (" + _vm._s(value === null || value === void 0 ? void 0 : value.username) + ") ")]) : _c('v-col', {
          staticClass: "py-0 text--secondary"
        }, [_vm._v(" 삭제된 회원입니다 ")]), _c('v-col', {
          staticClass: "px-1 py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "icon": "",
            "text": "",
            "to": `/console/users/${value === null || value === void 0 ? void 0 : value._id}`
          }
        }, [_c('v-icon', [_vm._v("mdi-link")])], 1)], 1), _c('v-col', {
          staticClass: "pl-1 py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "icon": "",
            "text": "",
            "to": {
              query: Object.assign({}, _vm.$route.query, {
                'writer._id': value === null || value === void 0 ? void 0 : value._id,
                page: null
              })
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-check")])], 1)], 1)], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false)), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  }), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        return _vm.create();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }