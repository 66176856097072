<template>
    <v-data-table v-bind="{ items, headers, loading }" disable-filtering disable-pagination disable-sort hide-default-footer :items-per-page="-1" :class="{ 'v-sheet--outlined': mode == 'form' }" ref="data-table">
        <template #top v-if="mode == 'form'">
            <view-section-title title="옵션정보">
                <template #actions>
                    <v-btn text icon color="primary" @click="push()"> <v-icon>mdi-plus</v-icon> </v-btn>
                </template>
            </view-section-title>
            <v-divider />
        </template>

        <template #[`header.code`]="{ header }" v-if="mode == 'form'">
            <shop-shipping-codes-form>
                <template #activator="{ attrs, on }">
                    <v-card v-bind="attrs" v-on="on" flat tile class="d-table-cell vertical-align-middle px-4" height="48" width="100%" style="color: rgba(0, 0, 0, 0.6)">
                        <span> {{ header.text }} </span>
                        <v-icon small> mdi-cog </v-icon>
                    </v-card>
                </template>
            </shop-shipping-codes-form>
        </template>

        <template #body="props" v-if="mode == 'form'">
            <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" @change="setNewIndex" :sort="!loading">
                <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
            </draggable>
        </template>

        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

        <template #[`item.code`]="{ item }">
            <item-code :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>
        <template #[`item.bundle.type`]="{ item }">
            <item-bundle-type :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>
        <template #[`item.charge.type`]="{ item }">
            <item-charge-type :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>
        <template #[`item.charge`]="{ item }">
            <item-charge :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>
        <template #[`item.island`]="{ item }">
            <item-island :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>
        <template #[`item.service`]="{ item }">
            <item-service :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>

        <template #[`item.actions`]="{ item }">
            <v-btn text icon color="red" @click="pull(item)">
                <v-icon> mdi-minus </v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import { initShopShipping, initShopShippingOption } from "@/assets/variables";

import Draggable from "vuedraggable";

import ViewSectionTitle from "@/components/console/dumb/view-section-title.vue";

import ItemCode from "./options/item-code.vue";
import ItemBundleType from "./options/item-bundle-type.vue";
import ItemChargeType from "./options/item-charge-type.vue";
import ItemCharge from "./options/item-charge.vue";
import ItemIsland from "./options/item-island.vue";
import ItemService from "./options/item-service.vue";
import ShopShippingCodesForm from "@/components/console/shop/shippings/codes/shop-shipping-codes-form.vue";
import { mapActions } from "vuex";

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },

        Draggable,

        ViewSectionTitle,

        ItemCode,
        ItemBundleType,
        ItemChargeType,
        ItemCharge,
        ItemIsland,
        ItemService,
        ShopShippingCodesForm,
    },
    props: {
        value: { type: Object, default: initShopShipping },

        mode: { type: String, default: "form" },
    },
    data: () => ({
        form: initShopShipping(),

        loading: false,
    }),
    computed: {
        items() {
            return this.form.options.map((item, index) => ({ ...item, index: index + 1 }));
        },
        headers() {
            return [
                { value: "index", text: "#", align: "end", width: +50, cellClass: "px-4 vertical-align-middle", hides: this.mode != "form" },
                { value: "code", text: "배송방식", width: 160, class: this.mode == "form" ? "pa-0" : undefined },
                { value: "bundle.type", text: "묶음방식", width: 110 },
                { value: "charge.type", text: "요금유형", width: 110 },
                { value: "charge", text: "요금상세", width: 330 },
                { value: "island", text: "도서산간\n추가요금", width: 180 },
                { value: "service", text: "설치서비스\n추가요금", width: 180 },
                { value: "actions", text: "", cellClass: "px-4 vertical-align-middle", width: 68, hides: this.mode != "form" },
            ]
                .filter(({ hides }) => !hides)
                .map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "pa-0") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));
        },
    },
    mounted() {
        this.init();
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        ...mapActions(["getSetting__console"]),
        init() {
            this.getSetting__console();
        },
        sync() {
            this.form = initShopShipping(this.value);
        },
        emit() {
            this.$emit("input", initShopShipping(this.form));
        },
        push(item = initShopShippingOption()) {
            this.form.options.push(item);
            this.emit();
        },
        pull(item) {
            const index = this.form.options.findIndex(({ tempId }) => tempId == item.tempId);
            if (0 <= index) {
                this.form.options.splice(index, 1);
            }
            this.emit();
        },
        updateItem(item) {
            const index = this.form.options.findIndex(({ tempId }) => tempId == item.tempId);
            if (0 <= index) {
                this.form.options.splice(index, 1, item);
            } else {
                this.form.options.push(item);
            }
            this.emit();
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let { oldIndex, newIndex } = moved;
                this.form.options.splice(newIndex, 0, ...this.form.options.splice(oldIndex, 1));

                this.emit();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
    .vertical-align-middle {
        vertical-align: middle !important;
    }

    .v-input__slot {
        background: transparent !important;
    }

    input[inputmode="decimal"] {
        text-align: right;
    }
}
</style>
