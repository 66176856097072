var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "outlined": "",
            "color": "primary"
          }
        }, 'v-btn', Object.assign({}, attrs, {
          loading: _vm.loading
        }), false), on), [_vm._v("송장수정")])];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("송장수정")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "배송방법",
      "items": _vm.deliveryMethods
    },
    model: {
      value: _vm.form.method,
      callback: function ($$v) {
        _vm.$set(_vm.form, "method", $$v);
      },
      expression: "form.method"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "택배업체",
      "items": _vm.deliveries,
      "item-text": "name",
      "item-value": "_id"
    },
    model: {
      value: _vm.form._id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_id", $$v);
      },
      expression: "form._id"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "운송장번호"
    },
    model: {
      value: _vm.form.number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "number", $$v);
      },
      expression: "form.number"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.put
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }