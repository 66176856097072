<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%">
            <list-heading v-bind="{ title }" v-model="showsSearch" showsFilterButton />

            <question-list-search v-bind="{ showsSearch, loading }" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template #[`item.content`]="{ item }">
                    <v-sheet flat color="transparent">
                        <v-card-subtitle class="caption"> 제목 : {{ item.subject || "-" }} </v-card-subtitle>
                        <v-card-text class="caption white-space-pre-line"> 내용 : {{ item.content || "-" }} </v-card-text>
                    </v-sheet>
                </template>

                <template #[`item.reply`]="{ item, value }">
                    <item-reply-edit :value="item" v-on="{ update }">
                        <v-sheet flat tile color="transparent">
                            <v-card-text class="caption white-space-pre-line"> {{ value || "-" }} </v-card-text>
                        </v-sheet>
                    </item-reply-edit>
                </template>

                <template #[`item.repliedAt`]="{ item, value }">
                    <item-replied-at-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="px-4 py-3 fill-height"> {{ value?.toDateTime?.() || value || "-" }} </v-card>
                    </item-replied-at-edit>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { CENTER_QUESTION_CODES, initDataTableHeaders } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import QuestionListSearch from "@/components/console/center/questions/question-list-search.vue";

import ItemReplyEdit from "@/components/console/dumb/edit/item-reply-edit.vue";
import ItemRepliedAtEdit from "@/components/console/dumb/edit/item-replied-at-edit.vue";

const headers = initDataTableHeaders([
    { width: 160, text: "유형", value: "typeText" },
    { width: 200, text: "작성자", value: "user", formatter: ({ name, username } = {}) => (name && username ? `${name}(${username})` : username || name || "-") },
    { width: 320, text: "내용", value: "content", cellClass: "pa-0" },
    { width: 320, text: "답변", value: "reply", cellClass: "pa-0" },
    { width: 100, text: "답변일시", value: "repliedAt", cellClass: "pa-0" },

    { width: 110, text: "작성일자", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: 110, text: "답변상태", value: "isReplied", formatter: (value, item) => (!!item.reply ? "답변완료" : "미답변") },
    { width: 100, text: "회원삭제", value: "deletedAt", formatter: (value) => value?.toDate?.() || value || "-" },
]);

export default {
    components: {
        ListHeading,
        QuestionListSearch,

        ItemReplyEdit,
        ItemRepliedAtEdit,
    },
    data: () => ({
        questions: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        title() {
            return (CENTER_QUESTION_CODES[this.$route.query.code]?.text || "문의") + " 목록";
        },
        items() {
            return this.questions;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            if (query.searchDateValue) query.searchDateKey = "createdAt";

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.questions = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, questions } = await api.console.center.questions.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.questions = questions;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;
            try {
                item = (await api.console.center.questions.put(item))?.item;
                this.updateItem(item);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(item) {
            const index = this.questions.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.questions.splice(index, 1, item);
            else {
                this.questions = [item, ...this.questions];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
