<template>
    <v-layout justify-center>
        <v-responsive max-width="480" width="100%">
            <list-heading title="색션 1 - 케어제품 링크" />

            <view-section-card hideTitle hideDivider v-bind="{ loading }">
                <v-card-text>
                    <v-row class="ma-n2">
                        <v-col cols="12" class="pa-2">
                            <v-text-field v-model="setting.main.care.text" label="이름" v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="12" class="pa-2">
                            <v-text-field v-model="setting.main.care.link" label="링크" v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="12" class="pa-2">
                            <v-text-field v-model="setting.main.care.desc" label="설명" v-bind="attrs_input" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="save" v-bind="{ loading }">저장하기</v-btn>
                </v-card-actions>
            </view-section-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input, initSetting } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ListHeading,
        ViewSectionCard,
    },
    data: () => ({
        setting: initSetting(),
        loading: false,

        attrs_input,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { setting } = await api.console.setting.get();
                this.setting = initSetting(setting);
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { main } = this.setting;
                var { setting } = await api.console.setting.put({ main });
                this.setting = initSetting(setting);
                alert("저장되었습니다");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
