<template>
    <v-select v-model="form.areas__available" v-bind="{ ...$attrs, items }" @input="emit" />
</template>

<script>
import sidoGugun from "@/assets/data/sido-gugun.json";
import { initSettingShopShippingCode } from "@/assets/variables";

const sidos = sidoGugun.map(({ name }) => name);

export default {
    props: {
        value: { type: Object, default: initSettingShopShippingCode },
    },
    data: () => ({
        form: initSettingShopShippingCode(),
        items: sidos,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initSettingShopShippingCode(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>