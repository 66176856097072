<template>
    <v-layout justify-center>
        <v-responsive width="100%" max-width="1280" height="100%">
            <list-heading title="개인결제 목록" v-model="showsSearch" showsFilterButton>
                <template #add-button>
                    <target-payment-view @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </target-payment-view>
                </template>
            </list-heading>

            <target-payment-search v-bind="{ loading, showsSearch }" />

            <v-row class="mt-n3">
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="caption" height="46" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" v-bind="{ ...attrs_switch, disabled: !item.stock, class: 'd-inline-block mt-0 ml-1 mr-n3 pa-0' }" @change="(shows) => update({ _id: item._id, shows })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <target-payment-view v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="{ ...attrs, loading }" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </target-payment-view>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small title="삭제">mdi-delete</v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch } from "@/assets/variables/attrs";
import { PRODUCT_TYPES } from "@/assets/variables/constants";
import { initDataTableHeaders } from "@/assets/variables/inits";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import TargetPaymentView from "@/components/console/shop/target-payments/target-payment-view.vue";
import TargetPaymentSearch from "@/components/console/shop/target-payments/target-payment-search.vue";

export default {
    components: {
        ListHeading,

        TargetPaymentView,
        TargetPaymentSearch,
    },
    data: () => ({
        products: [],

        limit: 20,
        summary: { totalCount: 0 },

        attrs_switch,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        headers() {
            const headers = initDataTableHeaders([
                {
                    text: "개인결제번호",
                    value: "code",
                    width: 180,
                },
                {
                    text: "결제명",
                    value: "name",
                },
                {
                    text: "결제금액",
                    value: "price",
                    formatter: (value) => (value?.format?.() || value || 0) + "원",
                    width: 100,
                    align: "end",
                },
                {
                    text: "회원",
                    value: "user",
                    formatter: ({ name, username }) => `${name || "-"} (${username || "-"})`,
                    width: 160,
                },
                {
                    text: "결제여부",
                    value: "isPaid",
                    formatter: (value, item) => (!item?.paymentAt ? "미결제" : "결제완료"),
                    width: +90,
                    align: "center",
                },
                {
                    text: "표시여부",
                    value: "shows",
                    width: +90,
                    align: "center",
                },
                {
                    text: "생성일시",
                    value: "createdAt",
                    formatterType: "datetime",
                    width: 100,
                },
                {
                    text: "결제일시",
                    value: "paymentAt",
                    formatterType: "datetime",
                    width: 100,
                },
                {
                    text: "",
                    value: "actions",
                    width: +88,
                },
            ]);

            return headers;
        },
        items() {
            return this.products.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;
            query.type = PRODUCT_TYPES.TARGET_PAYMENT.value;

            try {
                query.paymentAt = JSON.parse(query.paymentAt || "null");
            } catch {
                query.paymentAt = query.paymentAt;
            }

            switch (query.paymentAt) {
                case true: {
                    query.paymentAt = { $ne: null };
                    break;
                }
                case false: {
                    query.paymentAt = { $eq: null };
                    break;
                }
                case null:
                default: {
                    delete query.paymentAt;
                    break;
                }
            }

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.products = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, products } = await api.console.shop.products.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.products = products;
            } finally {
                this.loading = false;
            }
        },

        async update(product) {
            if (this.loading) return;
            else this.loading = true;
            try {
                product = (await api.console.shop.products.put(product))?.product;
                this.updateItem(product);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(product) {
            const index = this.products.findIndex(({ _id }) => _id == product._id);
            if (0 <= index) this.products.splice(index, 1, product);
            else {
                this.products = [product, ...this.products];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async remove(product) {
            try {
                if (confirm("해당 개인결제를 삭제하시겠습니까?")) {
                    await api.console.shop.products.delete(product);
                    await this.search();

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
