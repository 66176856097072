<template>
    <v-btn-toggle v-model="searchDateValue" dense color="primary" @change="emit">
        <v-btn v-for="{ text, value } in items" :key="text" v-bind="{ value }" height="40" color="white">{{ text }}</v-btn>
    </v-btn-toggle>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [null, null] },
    },
    data: () => ({
        searchDateValue: [null, null],
    }),
    computed: {
        items() {
            return [
                { text: "전체", value: [null, null] },
                { text: "오늘", value: [Date.now().toDate(), Date.now().toDate()] },
                { text: "어제", value: [this.$dayjs().subtract(1, "day").toISOString().toDate(), this.$dayjs().subtract(1, "day").toISOString().toDate()] },
                { text: "1주일", value: [this.$dayjs().subtract(6, "day").toISOString().toDate(), Date.now().toDate()] },
                { text: "1개월", value: [this.$dayjs().subtract(1, "month").add(1, "day").toISOString().toDate(), Date.now().toDate()] },
                { text: "6개월", value: [this.$dayjs().subtract(6, "months").add(1, "day").toISOString().toDate(), Date.now().toDate()] },
                { text: "12개월", value: [this.$dayjs().subtract(12, "months").add(1, "day").toISOString().toDate(), Date.now().toDate()] },
            ];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.searchDateValue = [...(this.value || [null, null])];
        },
        emit() {
            this.$emit("input", this.searchDateValue);
        },
    },
};
</script>

<style>
</style>