import { render, staticRenderFns } from "./shop-shipping-codes-form.vue?vue&type=template&id=3eb1e5d7"
import script from "./shop-shipping-codes-form.vue?vue&type=script&lang=js"
export * from "./shop-shipping-codes-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports