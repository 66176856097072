<template>
    <v-dialog v-model="shows" width="400">
        <template #activator="{ attrs, on }">
            <v-card flat tile v-bind="attrs" v-on="on">
                <v-card-text class="px-4 py-2 caption text-truncate" v-bind="{ title }">
                    {{ title }}
                </v-card-text>
            </v-card>
        </template>
        <v-card>
            <v-card-title class="subtitle-1"> 출고지 정보수정 </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12"> <v-text-field v-model="form.text" label="이름" v-bind="attrs_input" readonly /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.value" label="코드" v-bind="attrs_input" readonly /> </v-col>
                    <v-col cols="12" class="py-0"> <v-address-field v-model="form.warehouse" withGeolocation v-bind="attrs_input" :btnAttrs="{ color: 'secondary', dark: true }" /> </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn text @click="shows = false">취소</v-btn>
                <v-btn color="primary" text @click="emit">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { attrs_input, initSettingShopShippingCode } from "@/assets/variables";

import VAddressField from "@/components/console/dumb/v-address-field.vue";

export default {
    components: {
        VAddressField,
    },
    props: {
        value: { type: Object, default: initSettingShopShippingCode },
    },
    data: () => ({
        form: initSettingShopShippingCode(),

        shows: false,
        attrs_input,
    }),
    computed: {
        title() {
            let text = "";

            if (this.form?.warehouse?.postcode) text += `[${this.form?.warehouse?.postcode}]`;
            if (this.form?.warehouse?.address1) text += ` ${this.form?.warehouse?.address1}`;
            if (this.form?.warehouse?.address2) text += ` ${this.form?.warehouse?.address2}`;

            return text;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        shows() {
            if (this.shows) this.sync();
        },
    },
    methods: {
        async sync() {
            this.form = initSettingShopShippingCode(this.value);
        },
        async emit() {
            this.$emit("input", this.form);
            this.shows = false;
        },
    },
};
</script>

<style></style>
