var render = function render(){
  var _vm$form;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "560",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "light": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-2"
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "결제명"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading,
    disabled: !_vm.form.stock
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "결제금액",
      "suffix": "원"
    },
    model: {
      value: _vm.form.price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading,
    disabled: !_vm.form.stock
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('autocomplete-user', _vm._b({
    model: {
      value: _vm.form._user,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_user", $$v);
      },
      expression: "form._user"
    }
  }, 'autocomplete-user', Object.assign({}, _vm.attrs_input, {
    label: '회원',
    placeholder: '회원을 입력하세요',
    itemText: function (_ref2) {
      var name = _ref2.name,
        username = _ref2.username;
      return `${name || '-'} (${username || '-'})`;
    },
    sort: {
      name: 1,
      createdAt: 1,
      _id: 1
    },
    loading: _vm.loading,
    disabled: !_vm.isCreate
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-text-field', _vm._b({}, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    label: '결제상태',
    value: !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && _vm$form.paymentAt) ? '미결제' : '결제완료',
    readonly: true
  }), false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', _vm._b({
    model: {
      value: _vm.form.shows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shows", $$v);
      },
      expression: "form.shows"
    }
  }, 'v-switch', Object.assign({}, _vm.attrs_switch, {
    dense: true,
    disabled: !_vm.form.stock
  }), false), [_c('span', {
    staticClass: "caption white-space-pre-line",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.shows ? "목록\r\n표시" : "목록\r\n미표시") + " ")])])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }