var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "rounded-0 transparent",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.type`,
      fn: function (_ref2) {
        var _vm$GRADE_BENEFITS_TY;
        var item = _ref2.item;
        return [_c('span', [_vm._v(" " + _vm._s(((_vm$GRADE_BENEFITS_TY = _vm.GRADE_BENEFITS_TYPES[item.type]) === null || _vm$GRADE_BENEFITS_TY === void 0 ? void 0 : _vm$GRADE_BENEFITS_TY.text) || item.type || "-") + " ")]), item.value ? _c('span', [_vm._v(_vm._s(item.value) + "%")]) : _vm._e()];
      }
    }, {
      key: `item.isActive`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', _vm._b({
          attrs: {
            "disabled": "",
            "color": "grey darken-1"
          },
          model: {
            value: item.isActive,
            callback: function ($$v) {
              _vm.$set(item, "isActive", $$v);
            },
            expression: "item.isActive"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.value,
    headers: _vm.headers
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }