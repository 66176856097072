var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "width": "100%"
    }
  }, [_c('list-heading', _vm._b({
    attrs: {
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }, 'list-heading', {
    title: _vm.title
  }, false)), _c('question-list-search', _vm._b({}, 'question-list-search', {
    showsSearch: _vm.showsSearch,
    loading: _vm.loading
  }, false)), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.content`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-sheet', {
          attrs: {
            "flat": "",
            "color": "transparent"
          }
        }, [_c('v-card-subtitle', {
          staticClass: "caption"
        }, [_vm._v(" 제목 : " + _vm._s(item.subject || "-") + " ")]), _c('v-card-text', {
          staticClass: "caption white-space-pre-line"
        }, [_vm._v(" 내용 : " + _vm._s(item.content || "-") + " ")])], 1)];
      }
    }, {
      key: `item.reply`,
      fn: function (_ref3) {
        var item = _ref3.item,
          value = _ref3.value;
        return [_c('item-reply-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-sheet', {
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_c('v-card-text', {
          staticClass: "caption white-space-pre-line"
        }, [_vm._v(" " + _vm._s(value || "-") + " ")])], 1)], 1)];
      }
    }, {
      key: `item.repliedAt`,
      fn: function (_ref4) {
        var _value$toDateTime;
        var item = _ref4.item,
          value = _ref4.value;
        return [_c('item-replied-at-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "px-4 py-3 fill-height",
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s((value === null || value === void 0 ? void 0 : (_value$toDateTime = value.toDateTime) === null || _value$toDateTime === void 0 ? void 0 : _value$toDateTime.call(value)) || value || "-") + " ")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading,
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }