<template>
    <v-edit-dialog :return-value.sync="item.state" large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input> <v-select v-model="item.state" :items="states" v-bind="attrs_input" class="mt-3" /> </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input, FORM_STATES } from "@/assets/variables";

const states = Object.values(FORM_STATES);

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: {},
        states,
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = JSON.parse(JSON.stringify(this.value));
        },
        save() {
            let { _id, state } = this.item;
            let form = { _id, state };

            this.$emit("update", form);
        },
    },
};
</script>

<style>
</style>