<template>
    <view-section-card title="등급할인" :hideDivider="!form.isActive">
        <template #actions>
            <v-switch v-model="form.isActive" v-bind="attrs_switch" @input="emit" />
        </template>
        <v-expand-transition>
            <v-sheet v-show="form.isActive">
                <v-card-text>
                    <v-row class="ma-n2">
                        <v-col cols="12" sm="" class="pa-2">
                            <v-currency-field v-model="form.value" v-bind="fieldAttrs" @input="emit" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_input, attrs_switch, initUserLevelDiscount } from "@/assets/variables";
import { USER_LEVEL_DISCOUNT_TYPES, USER_LEVEL_DISCOUNT_UNITS } from "@/assets/variables/constants";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initUserLevelDiscount },
    },
    data: () => ({
        form: initUserLevelDiscount(),

        attrs_switch,
    }),
    computed: {
        fieldAttrs() {
            let label = USER_LEVEL_DISCOUNT_TYPES[this.form.type]?.text;
            let { suffix, min, max } = USER_LEVEL_DISCOUNT_UNITS[this.form.unit] || {};

            let attrs = { label, suffix, min, max };
            if (suffix == undefined) delete attrs.suffix;
            if (min == undefined) delete attrs.min;
            if (max == undefined) delete attrs.max;

            return { ...attrs_input, ...attrs };
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUserLevelDiscount(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style></style>
