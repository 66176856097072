<template>
    <view-section-card title="기본정보">
        <v-card-text>
            <v-row class="ma-n2">
                <v-col cols="12" class="pa-2">
                    <v-text-field v-model="form.name" label="정책명" v-bind="attrs_input" @input="emit" />
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-datetime-field v-model="form.startsAt" label="사용시작" placeholder="시작일시 없음" padding="0" v-bind="attrs_input" @input="emit" />
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-datetime-field v-model="form.endsAt" label="사용종료" placeholder="종료일시 없음" padding="0" v-bind="attrs_input" @input="emit" />
                </v-col>
            </v-row>
        </v-card-text>
    </view-section-card>
</template>

<script>
import { attrs_input, initShopShipping } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";

export default {
    components: {
        ViewSectionCard,
        VDatetimeField,
    },
    props: {
        value: { type: Object, default: initShopShipping },
    },
    data: () => ({
        form: initShopShipping(),

        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopShipping(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>