var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-field-card', _vm._b({}, 'view-field-card', {
    label: _vm.label,
    disabled: _vm.disabled
  }, false), [_c('v-row', {
    staticClass: "ma-n4"
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "flat": "",
      "color": "transparent"
    }
  }, [_vm.isCurrency ? [_c('v-currency-field', _vm._b({
    attrs: {
      "append-icon": "mdi-plus"
    },
    on: {
      "click:append": _vm.add,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.add.apply(null, arguments);
      }
    },
    model: {
      value: _vm.field,
      callback: function ($$v) {
        _vm.field = $$v;
      },
      expression: "field"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input__verticalTable, _vm.$attrs), false))] : [_c('v-text-field', _vm._b({
    attrs: {
      "append-icon": "mdi-plus"
    },
    on: {
      "click:append": _vm.add,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.add.apply(null, arguments);
      }
    },
    model: {
      value: _vm.field,
      callback: function ($$v) {
        _vm.field = $$v;
      },
      expression: "field"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__verticalTable, _vm.$attrs), false))], _c('v-divider'), _c('v-card-text', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('v-chip-group', {
    staticClass: "mt-n1 mb-n2",
    staticStyle: {
      "min-height": "48px"
    },
    attrs: {
      "column": ""
    }
  }, [_c('draggable', {
    attrs: {
      "disabled": !_vm.draggable
    },
    model: {
      value: _vm.items,
      callback: function ($$v) {
        _vm.items = $$v;
      },
      expression: "items"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-chip', {
      key: item,
      attrs: {
        "outlined": "",
        "label": "",
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.remove(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1), _vm._t("chip-area")], 2)], 2)], 1)], 1), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }