<template>
    <view-section-card title="사용">
        <template #actions> <v-switch v-model="form.usage.isActive" v-bind="attrs_switch" @change="emit" /> </template>
        <v-card :disabled="!form.usage.isActive" flat>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col cols="12" md="12" class="pa-2">
                        <form-usage-dates v-model="form" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <v-row class="mx-n4"> <v-divider /> </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <form-usage-target v-model="form" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <v-row class="mx-n4"> <v-divider /> </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <form-usage-limit v-model="form" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <v-row class="mx-n4"> <v-divider /> </v-row>
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <view-field-card label="중복사용 가능여부">
                            <v-switch v-model="form.usage.combinable" v-bind="attrs_switch" class="my-n1" @change="emit">
                                <template #label>
                                    <span class="caption">
                                        <template v-if="form.usage.combinable">중복사용 가능</template>
                                        <template v-else>중복사용 불가</template>
                                    </span>
                                </template>
                            </v-switch>
                        </view-field-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { attrs_switch, initShopCoupon } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import FormUsageDates from "./form-usage-dates.vue";
import FormUsageLimit from "./form-usage-limit.vue";
import FormUsageTarget from "./form-usage-target.vue";
import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";

export default {
    components: {
        ViewSectionCard,

        FormUsageDates,
        FormUsageLimit,
        FormUsageTarget,
        ViewFieldCard,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),

        attrs_switch,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
