var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "400",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    staticClass: "d-flex flex-column",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "light": "",
      "tile": "",
      "max-height": "90vh"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-5",
    staticStyle: {
      "flex": "0 1 auto",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.userTypes,
      "label": "회원유형"
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "등급레벨"
    },
    model: {
      value: _vm.form.index,
      callback: function ($$v) {
        _vm.$set(_vm.form, "index", $$v);
      },
      expression: "form.index"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "등급명칭"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('form-criterion', {
    model: {
      value: _vm.form.criteria[0],
      callback: function ($$v) {
        _vm.$set(_vm.form.criteria, 0, $$v);
      },
      expression: "form.criteria[0]"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('form-discount', {
    model: {
      value: _vm.form.discounts[0],
      callback: function ($$v) {
        _vm.$set(_vm.form.discounts, 0, $$v);
      },
      expression: "form.discounts[0]"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('view-section-card', {
    attrs: {
      "title": "초기등급",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          attrs: {
            "disabled": _vm.value.isWelcome && !_vm.isCreate
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.isWelcome,
            callback: function ($$v) {
              _vm.$set(_vm.form, "isWelcome", $$v);
            },
            expression: "form.isWelcome"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.isWelcome,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isWelcome", $$v);
      },
      expression: "form.isWelcome"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "140px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.isActive,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isActive", $$v);
      },
      expression: "form.isActive"
    }
  }, 'v-switch', _vm.attrs_switch, false), [_c('span', {
    staticClass: "subtitle-2",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.isActive ? "활성화" : "비활성화") + " ")])])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }