<template></template>

<script>
export default {
    components: {},
    methods: {
        scopeIncludes(criterion) {
            return this.payload?.scope?.includes?.(criterion);
        },
    },
    computed: {
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload.scope || [];
        },
    },
    mounted() {
        if (this.scopeIncludes("console")) this.$router.replace("/console/dashboard");
        if (this.scopeIncludes("admin02")) this.$router.replace("/console/dashboard");
        if (this.scopeIncludes("admin03")) this.$router.replace("/console/statistics");
    },
};
</script>
