<template>
    <v-dialog v-model="shows" width="1280" :persistent="loading">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card outlined>
            <v-card-title>배송방식 설정</v-card-title>
            <v-divider />
            <form-table v-model="form.shop.shippingCodes" />
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn text color="red" @click="shows = false">취소하기</v-btn>
                <v-btn text color="primary" @click="save">저장하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { mapActions, mapGetters } from "vuex";
import { attrs_input, attrs_switch, initSetting, initSettingShopShippingCode } from "@/assets/variables";

import FormTable from "./form-table.vue";

export default {
    components: {
        FormTable,
    },
    props: {
        value: { type: Object, default: initSetting },
    },
    data: () => ({
        form: initSetting(),

        attrs_input,
        attrs_switch,

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapGetters(["shippingCodes"]),
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.sync();
        },
    },
    methods: {
        ...mapActions(["getSetting__console"]),
        sync() {
            const setting = initSetting(this.value);
            setting.shop.shippingCodes = this.shippingCodes.map(initSettingShopShippingCode);
            this.form = setting;
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                const { shop } = this.form;
                const { setting } = await api.console.setting.put({ shop });
                this.getSetting__console();
                this.shows = false;
                this.$emit("input", setting);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>