var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "등급할인",
      "hideDivider": !_vm.form.isActive
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.form.isActive,
            callback: function ($$v) {
              _vm.$set(_vm.form, "isActive", $$v);
            },
            expression: "form.isActive"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }, [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.isActive,
      expression: "form.isActive"
    }]
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('v-currency-field', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.value,
      callback: function ($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }, 'v-currency-field', _vm.fieldAttrs, false))], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }